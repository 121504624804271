import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import Nav from "./Nav";
import Footer from "./Footer";
import CreateAccount from "./components/CreateAccount";
import Login from "./components/Login";
import ResetPassword from "./components/ResetPassword";
import Challenge from "./Challenge/Challenge";
import Wine from "./Wine/Wine";
import AdminDashboard from "./AdminDashboard/AdminDashboard";
import CreateProduct from "./Products/CreateProduct";
import ListCoupons from "./Coupons/ListCoupons";
import CreateCoupons from "./Coupons/CreateCoupons";
import Orders from "./Accounts/Orders";
import Transaction from "./Accounts/Transaction";
import ProductList from "./Products/ProductList";
import Category from "./Products/Category";
import ProductDetails from "./Products/ProductDetails";
import EditProduct from "./Products/EditProduct";
import OrdersList from "./Orders/OrdersList";
import OrderDetails from "./Orders/OrderDetails";
import Wishlist from "./components/Wishlist";
import Demo from "./Home/Demo";

function App() {
  const showNavAndFooter = ![
    "/Dashboard",
    "/CreateProduct",
    "/ListCoupons",
    "/CreateCoupon",
    "/Orders",
    "/Transaction",
    "/ProductList",
    "/Category",
    "/ProductDetails",
    "/EditProduct",
    "/OrdersList",
    "/OrderDetails",
  ].includes(window.location.pathname);

  return (
    <div className="App">
      {/* <Demo /> */}
      {showNavAndFooter && <Nav />}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/CreateAccount" element={<CreateAccount />}></Route>
          <Route path="/Login" element={<Login />}></Route>
          <Route path="/ResetPassword" element={<ResetPassword />}></Route>
          <Route path="/Challenge" element={<Challenge />}></Route>
          <Route path="/Wine" element={<Wine />}></Route>
          <Route path="/Dashboard" element={<AdminDashboard />} />
          <Route path="/CreateProduct" element={<CreateProduct />} />
          <Route path="/ListCoupons" element={<ListCoupons />} />
          <Route path="/CreateCoupon" element={<CreateCoupons />} />
          <Route path="/Orders" element={<Orders />} />
          <Route path="/Transaction" element={<Transaction />} />
          <Route path="/ProductList" element={<ProductList />} />
          <Route path="/Category" element={<Category />} />
          <Route path="/ProductDetails" element={<ProductDetails />} />
          <Route path="/EditProduct" element={<EditProduct />} />
          <Route path="/OrdersList" element={<OrdersList />} />
          <Route path="/OrderDetails" element={<OrderDetails />} />
          <Route path="/Wishlist" element={<Wishlist />} />
        </Routes>
      </BrowserRouter>
      {showNavAndFooter && <Footer />}
    </div>
  );
}

export default App;
