import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdHome } from "react-icons/md";
import "../AdminDashboard/AdminDashboard.css";
import { FaPhoneAlt } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
import { IoIosSearch } from "react-icons/io";
import { BsFilterLeft } from "react-icons/bs";
import { AiOutlineAppstore } from "react-icons/ai";
import { GoPackage } from "react-icons/go";
import { TiShoppingCart } from "react-icons/ti";
import { FaRupeeSign } from "react-icons/fa";
import { RiCoupon3Line } from "react-icons/ri";
import { FaHandsHelping } from "react-icons/fa";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("");

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);

    // You can add more conditions or redirect to other pages as needed
    if (selectedValue === "CreateProduct") {
      navigate("/CreateProduct");
    } else if (selectedValue === "ListCoupons") {
      navigate("/ListCoupons");
    }
  };
  return (
    <div>
      <main>
        {/* Header Section*/}
        <section>
          <div className="container-fluid bg-brown txt-gwhite">
            <div className="header-section d-flex flex-wrap">
              <div className="row">
                <div className="col-12">
                  <div className="dashboard-head">
                    <div className="row">
                      <div className="col-6">
                        <div className="admin-head-home txt-left pt-2-5 ">
                          <MdHome />
                        </div>
                        <div className="admin-head-address txt-left f-12 mt-1 ml-10p p-3">
                          <small>
                            3/66 3,ST-22 Kuruvilaikadu,Eraviputhurkadai.
                          </small>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="admin-home-number">
                          <div className="admin-phone-icon f-12 mt-1">
                            <FaPhoneAlt />
                          </div>
                          <div className="admin-phone-number f-10 txt-right">
                            <p>73739 32235</p>
                            <p className="admin-head-num2 ms-5">94880 68189</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Search Section */}
        <section>
          <div className="container-fluid">
            <div className="search-section d-flex flex-wrap txt-center mt-4">
              <div className="row">
                <div className="col-12">
                  <div className="dashboard-search">
                    <div className="row">
                      <div className="col-2">
                        <div className="dashboard-logo">
                          <h3>BISMI.</h3>
                        </div>
                      </div>
                      <div className="col-8">
                        <div className="dashboard-search">
                          <div className="row">
                            <div className="col-2 search-loc">
                              <div className="search-location">
                                <p className="pos-abs mt-1">
                                  <IoLocationOutline />
                                </p>
                                <p className="mt-1">Search</p>
                              </div>
                            </div>
                            <div className="col-8 txt-left">
                              <input
                                className="w-100 h-36"
                                placeholder="Search List.."
                                type="text"
                                name=""
                                id=""
                              />
                            </div>
                            <div className="col-2 txt-left">
                              <button className="search-btn" type="button">
                                <IoIosSearch className="mt--5 txt-gwhite" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="dashboard-profile mt-1">
                          <div className="row">
                            <div className="col">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "assets/images/dash-profile.jpg"
                                }
                                alt=""
                                className="dash-img"
                              />
                            </div>
                            <div className="col txt-left">
                              <p className="f-10 ms-3 w-100 mt-1">
                                Hello,
                                <br />
                                <small className="fw-bold">My Account</small>
                              </p>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Categories Section */}
        <section>
          <div className="container-fluid">
            <div className="categories-section">
              <div className="row">
                <div className="col-12">
                  <div className="categories-select f-14 ml-7p">
                    <div className="row">
                      <div className="col">
                        <div className="filter-cat">
                          <button
                            type="button"
                            className="txt-gwhite b-none bg-brown p-1 br-rad-2"
                          >
                            <BsFilterLeft />
                            <small>All Categories</small>
                          </button>
                        </div>
                      </div>
                      <div className="col">
                        <div className="categories-dash">
                          <button
                            type="button"
                            className=" txt-brown bg-trans b-none"
                          >
                            <AiOutlineAppstore />
                            <small>Dashboard</small>
                          </button>
                        </div>
                      </div>
                      <div className="col flt-left">
                        <div className="categories-products">
                          <button type="button" className="bg-trans b-none">
                            <GoPackage />
                            <select
                              name=""
                              id=""
                              className="b-none bg-trans w-60 f-12"
                              onChange={handleSelectChange}
                              value={selectedOption}
                            >
                              <option value="">Products</option>
                              <option value="">Category</option>
                              <option value="">Product List</option>
                              <option value="">Product Detail</option>
                              <option value="CreateProduct">Add Product</option>
                            </select>
                          </button>
                        </div>
                      </div>
                      <div className="col">
                        <div className="categories-orders flt-left">
                          <button className="b-none bg-trans" type="button">
                            <TiShoppingCart />
                            <select
                              name=""
                              id=""
                              className="b-none bg-trans w-50 f-12"
                              onChange={handleSelectChange}
                              value={selectedOption}
                            >
                              <option value="">Orders</option>
                              <option value="">Order List</option>
                              <option value="">Order Tracking</option>
                              <option value="">Order Details</option>
                            </select>
                          </button>
                        </div>
                      </div>
                      <div className="col">
                        <div className="categories-accounts">
                          <button type="button" className="b-none bg-trans">
                            <FaRupeeSign />
                            <select
                              name=""
                              id=""
                              className="b-none bg-trans w-70 f-12"
                              onChange={handleSelectChange}
                              value={selectedOption}
                            >
                              <option value="">Accounts</option>
                              <option value="">Order</option>
                              <option value="">Transactions</option>
                            </select>
                          </button>
                        </div>
                      </div>
                      <div className="col coupons-left">
                        <div className="categories-coupons">
                          <button type="button" className="b-none bg-trans">
                            <RiCoupon3Line />
                            <select
                              name=""
                              id=""
                              className="b-none bg-trans w-60 f-12"
                              onChange={handleSelectChange}
                              value={selectedOption}
                            >
                              <option value="">Coupons</option>
                              <option value="ListCoupons">List Coupons</option>
                              <option value="">Create Coupons</option>
                            </select>
                          </button>
                        </div>
                      </div>
                      <div className="col">
                        <div className="today-orders flt-right">
                          <button
                            type="button"
                            className="tdy-orders-bg b-none txt-gwhite hgt-28 br-rad-2 f-12"
                          >
                            <FaHandsHelping />
                            Today Orders
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Bismi Admin Panel */}
        <section>
          <div className="container-fluid Admin-pnl-head-bg mt-3">
            <div className="row">
              <div className="col-12">
                <div className="ps-7p">
                  <h6 className="mt-2 txt-brown">DASHBOARD</h6>
                  <p className="f-10">Bismi Admin Panel</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default AdminDashboard;
