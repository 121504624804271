import React from "react";
import { IoMdSearch } from "react-icons/io";
import { FaRegUser } from "react-icons/fa";
import { BsCart4 } from "react-icons/bs";
import { FaRegHeart } from "react-icons/fa";
import { FiPhone } from "react-icons/fi";
import logo from "./assets/images/logo3.png";

import "./Nav.css";

const Nav = () => {
  return (
    <div>
      <nav class="navbar navbar-expand-sm justify-content-center">
        <img src={logo} style={{ width: "70px" }} alt="logo"></img>
        <ul class="navbar-nav">
          <li class="nav-item" style={{ opacity: 0 }}>
            <a class="nav-link" href="#gallery">
              {" "}
              SPECIAL GALLERY
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#home">
              HOME
            </a>
          </li>
          <li class="nav-item" id="aboutus">
            <a className="nav-link" href="#aboutUs">
              ABOUT US
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" style={{ color: "white" }} href="#dryfruits">
              GALLERY
            </a>
          </li>
          <li class="nav-item" style={{ opacity: 0 }}>
            <a class="nav-link" href="#giftcombos">
              {" "}
              GIFT COMBOS
            </a>
          </li>
        </ul>
        <div style={{ opacity: 0 }}>
          <IoMdSearch class="nav-icon" />
          <FaRegUser class="nav-icon1" />
          <BsCart4 class="nav-icon2" style={{ marginRight: "27px" }} />
          <a href="#">
            <FaRegHeart
              class="nav-icon3"
              style={{ marginRight: "27px", color: "white" }}
            />
          </a>
        </div>
        <FiPhone class="call" />
        <p style={{ fontSize: "12px" }}>+917373932235</p>
      </nav>
    </div>
  );
};

export default Nav;
