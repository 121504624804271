import React, { useState } from 'react'
import {Link} from "react-router-dom";

const CreateAccount = () => {
    const [backgroundColor, setBgColor] = useState('#663300');
    const [returnBgColor, setReturnBgColor] = useState('#663300');
  return (
    <div>
      <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
        <div style={{flex:1, flexDirection:"column", display:"flex"}}>
          <img
            style={{ marginTop: -80 }}
            className="w-100"
            src={"../assets/images/CreateAccount-back.jpg"}
            alt=""
            srcset=""
          />
        </div>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          marginTop: -185,
        }}
      >
        <div style={{flexDirection:"column", display:"flex", flex:1}}>
        <h2
          className="f-35"
          style={{
            fontFamily: "'Jim Nightshade', cursive",
            color: "white",
            marginLeft: "27%",
          }}
        >
          Account
        </h2>

        <h5 style={{ fontSize: 18, color: "white", marginLeft: "24%" }}>
          Home / Create Account
        </h5>
        </div>
      </div>
      <div>
        
      </div>

      <div
        style={{
          flex: 1,
          display: "flex",
          width: "100%",
          paddingLeft: "20%",
          paddingRight: "20%",
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            backgroundColor: "#f7f7f7",
            borderRadius: 10,
            marginTop: "20%",
            marginBottom: 60,
          }}
        >
          <div
            style={{
              flexDirection: "column",
              flex: 1,
              display: "flex",
              marginBottom: 30,
              paddingLeft: 63,
              paddingRight: 63,
            }}
          >
            <Link to='/Login'><button
              style={{
                width: 30,
                marginLeft: "52%",
                marginTop: "2%",
                backgroundColor: "#663300",
                position:"absolute",
                border: "none",
                color: "white",
              }}
            >
              <span style={{paddingTop: -10}}>x</span>
            </button></Link>

            <input
              style={{ marginTop: 50, height:48 }}
              className="form-control f-14"
              placeholder="First Name"
              type="text"
              name=""
              id=""
            />
            <input
              style={{ marginTop: 30, height:48 }}
              className="form-control f-14"
              placeholder="Last Name"
              type="text"
              name=""
              id=""
            />
            <input
              style={{ marginTop: 30, height:48 }}
              className="form-control f-14"
              placeholder="Email"
              type="email"
              name=""
              id=""
            />
            <input
              style={{ marginTop: 30, height:48 }}
              className="form-control f-14"
              placeholder="Password"
              type="password"
              name=""
              id=""
            />
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                marginTop: 30,
              }}
            >
              <button
              onMouseEnter={() => setBgColor('orange')}
          onMouseLeave={() => setBgColor('#663300')}
                style={{
                  backgroundColor,
                  border: "none",
                  color: "white",
                  fontSize: 14,
                  width: 70,
                  paddingLeft: 10,
                  paddingRight:10,
                  height: 35,
                }}
              >
                Create
              </button>
              <button
              onMouseEnter={() => setReturnBgColor('orange')}
          onMouseLeave={() => setReturnBgColor('#663300')}
                style={{
                  marginLeft: "73%",
                  backgroundColor: returnBgColor,
                  border: "none",
                  color: "white",
                  fontSize: 14,
                  height: 35,
                  width: 125,
                  paddingLeft: 10,
                  paddingRight:10,
                }}
              >
                Return to Store
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateAccount
