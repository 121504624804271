import React from "react";
import "../Wine/Wine.css";
import { IoIosStarOutline } from "react-icons/io";

const Wine = () => {
  return (
    <div>
      <main>
        {/* Wine Back Image Section */}
        <section>
          <div className="container-fluid d-flex flex-wrap">
            <div className="wineprod-section">
              <div className="row">
                <div className="col-12">
                  <img
                    fluid
                    className="crt-acc-back w-100 mb-5"
                    src={process.env.PUBLIC_URL + "assets/images/Wine-back.png"}
                    alt=""
                    srcset=""
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="crt-acc-cnt txt-gwhite ml-45-8p ">
                    <div className="cntback-head">
                      <h2 className="f-35 mt--12">Wine</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Wine Products Section */}
        <section>
          <div className="container-fluid d-flex">
            <div className="row">
              <div className="col-3">
                <div className="filter-section br-1">
                  <div className="filter-head">
                    <div className="row txt-center">
                      <div className="col-6">Filter</div>
                      <div className="col-6">Remove all</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-9">
                <div className="wine-products">
                  <div className="wineprod-head br-1">
                    <div className="row">
                      <div className="col-6 ps-5">
                        <input
                          className="w-100 out-none"
                          placeholder="Search Products..."
                          type="text"
                          name=""
                          id=""
                        />
                      </div>
                      <div className="col-6 txt-right">
                        Sort by
                        <select name="" id="">
                          <option value="Featured">Featured</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="wineproducts">
                    <div className="row mt-5">
                      <div className="col-4">
                        <img
                          className="w-100 h-max"
                          src={
                            process.env.PUBLIC_URL +
                            "assets/images/wine-product1.png"
                          }
                          alt=""
                          srcset=""
                        />
                        <div className="product-ratings mt-3">
                          <p className="w-35 d-flex justify-content-between">
                            <IoIosStarOutline />
                            <IoIosStarOutline />
                            <IoIosStarOutline />
                            <IoIosStarOutline />
                            <IoIosStarOutline />
                          </p>
                          <p className="txt-brown fw-bold f-16">
                            Premium Sweet Wine
                          </p>
                          <p>Rs.1200.00</p>
                        </div>
                      </div>
                      <div className="col-4">
                        <img
                          className="w-100 h-max"
                          src={
                            process.env.PUBLIC_URL +
                            "assets/images/wine-product2.png"
                          }
                          alt=""
                          srcset=""
                        />
                        <div className="product-ratings mt-3">
                          <p className="w-35 d-flex justify-content-between">
                            <IoIosStarOutline />
                            <IoIosStarOutline />
                            <IoIosStarOutline />
                            <IoIosStarOutline />
                            <IoIosStarOutline />
                          </p>
                          <p className="txt-brown fw-bold f-16">Arabic Wine</p>
                          <p>Rs.600.00</p>
                        </div>
                      </div>
                      <div className="col-4">
                        <img
                          className="w-100 h-max"
                          src={
                            process.env.PUBLIC_URL +
                            "assets/images/wine-product3.png"
                          }
                          alt=""
                          srcset=""
                        />
                        <div className="product-ratings mt-3">
                          <p className="w-35 d-flex justify-content-between">
                            <IoIosStarOutline />
                            <IoIosStarOutline />
                            <IoIosStarOutline />
                            <IoIosStarOutline />
                            <IoIosStarOutline />
                          </p>
                          <p className="txt-brown fw-bold f-16">Honey Wine</p>
                          <p>Rs.900.00</p>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-5">
                      <div className="col-4">
                        <img
                          className="w-100 h-max"
                          src={
                            process.env.PUBLIC_URL +
                            "assets/images/wine-product4.png"
                          }
                          alt=""
                          srcset=""
                        />
                        <div className="product-ratings mt-3">
                          <p className="w-35 d-flex justify-content-between">
                            <IoIosStarOutline />
                            <IoIosStarOutline />
                            <IoIosStarOutline />
                            <IoIosStarOutline />
                            <IoIosStarOutline />
                          </p>
                          <p className="txt-brown fw-bold f-16">
                            Bulk Honey Wine
                          </p>
                          <p>Rs.900.00</p>
                        </div>
                      </div>
                      <div className="col-4">
                        <img
                          className="w-100 h-max"
                          src={
                            process.env.PUBLIC_URL +
                            "assets/images/wine-product5.png"
                          }
                          alt=""
                          srcset=""
                        />
                        <div className="product-ratings mt-3">
                          <p className="w-35 d-flex justify-content-between">
                            <IoIosStarOutline />
                            <IoIosStarOutline />
                            <IoIosStarOutline />
                            <IoIosStarOutline />
                            <IoIosStarOutline />
                          </p>
                          <p className="txt-brown fw-bold f-16">
                            Sweet and Honey Wine
                          </p>
                          <p>Rs.400.00</p>
                        </div>
                      </div>
                      <div className="col-4">
                        <img
                          className="w-100 h-max"
                          src={
                            process.env.PUBLIC_URL +
                            "assets/images/wine-product6.png"
                          }
                          alt=""
                          srcset=""
                        />
                        <div className="product-ratings mt-3">
                          <p className="w-35 d-flex justify-content-between">
                            <IoIosStarOutline />
                            <IoIosStarOutline />
                            <IoIosStarOutline />
                            <IoIosStarOutline />
                            <IoIosStarOutline />
                          </p>
                          <p className="txt-brown fw-bold f-16">
                            Premium Sweet Wine
                          </p>
                          <p>Rs.900.00</p>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-5">
                      <div className="col-4">
                        <img
                          className="w-100 h-max"
                          src={
                            process.env.PUBLIC_URL +
                            "assets/images/wine-product7.png"
                          }
                          alt=""
                          srcset=""
                        />
                        <div className="product-ratings mt-3">
                          <p className="w-35 d-flex justify-content-between">
                            <IoIosStarOutline />
                            <IoIosStarOutline />
                            <IoIosStarOutline />
                            <IoIosStarOutline />
                            <IoIosStarOutline />
                          </p>
                          <p className="txt-brown fw-bold f-16">Premium Wine</p>
                          <p>Rs.150.00</p>
                        </div>
                      </div>
                      <div className="col-4">
                        <img
                          className="w-100 h-max"
                          src={
                            process.env.PUBLIC_URL +
                            "assets/images/wine-product8.png"
                          }
                          alt=""
                          srcset=""
                        />
                        <div className="product-ratings mt-3">
                          <p className="w-35 d-flex justify-content-between">
                            <IoIosStarOutline />
                            <IoIosStarOutline />
                            <IoIosStarOutline />
                            <IoIosStarOutline />
                            <IoIosStarOutline />
                          </p>
                          <p className="txt-brown fw-bold f-16">
                            Premium Sweet Wine
                          </p>
                          <p>Rs.800.00</p>
                        </div>
                      </div>
                      <div className="col-4">
                        <img
                          className="w-100 h-max"
                          src={
                            process.env.PUBLIC_URL +
                            "assets/images/wine-product9.png"
                          }
                          alt=""
                          srcset=""
                        />
                        <div className="product-ratings mt-3">
                          <p className="w-35 d-flex justify-content-between">
                            <IoIosStarOutline />
                            <IoIosStarOutline />
                            <IoIosStarOutline />
                            <IoIosStarOutline />
                            <IoIosStarOutline />
                          </p>
                          <p className="txt-brown fw-bold f-16">
                            Bulk Honey Wine
                          </p>
                          <p>Rs.900.00</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Wine;
