import React, { useState } from "react";
import {Link} from "react-router-dom";

const ResetPassword = () => {
  const [backgroundColor, setBgColor] = useState("#663300");
  const [continuecolor, setContinuecolor] = useState("#663300");

  return (
    <div>
      <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
        <div style={{ flex: 1, flexDirection: "column", display: "flex" }}>
          <img
            style={{ marginTop: -80 }}
            className="w-100"
            src={"../assets/images/CreateAccount-back.jpg"}
            alt=""
            srcset=""
          />
        </div>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          marginTop: -185,
        }}
      >
        <div style={{ flexDirection: "column", display: "flex", flex: 1 }}>
          <h2
            className="f-35"
            style={{
              fontFamily: "'Jim Nightshade', cursive",
              color: "white",
              marginLeft: "27%",
            }}
          >
            Account
          </h2>

          <h5 style={{ fontSize: 18, color: "white", marginLeft: "26%" }}>
            Home / Account
          </h5>
        </div>
      </div>
      <div></div>

      <div
        style={{
          flex: 1,
          display: "flex",
          width: "100%",
          paddingLeft: "20%",
          paddingRight: "20%",
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            backgroundColor: "#f7f7f7",
            borderRadius: 10,
            marginTop: "20%",
            marginBottom: 40,
          }}
        >
          <div
            style={{
              flexDirection: "column",
              flex: 1,
              display: "flex",
              marginBottom: 30,
              paddingLeft: 63,
              paddingRight: 63,
            }}
          >
            <Link to='/Login'><button
              style={{
                width: 30,
                marginLeft: "52%",
                marginTop: "2%",
                backgroundColor: "#663300",
                position: "absolute",
                border: "none",
                color: "white",
              }}
            >
              <span style={{ paddingTop: -10 }}>x</span>
            </button></Link>
            <label
              htmlFor=""
              style={{
                marginTop: 30,
                fontFamily: "'Jim Nightshade', cursive",
                fontSize: 24,
                color: "#663300",
              }}
            >
              Reset your password
            </label>

            <label
              style={{ marginTop: 20, fontSize: 15, color: "black" }}
              htmlFor=""
            >
              We will send you an email to reset your password.
            </label>
            <input
              style={{ marginTop: 20, height: 48 }}
              className="form-control f-14"
              placeholder="Email"
              type="email"
              name=""
              id=""
            />

            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                marginTop: 40,
              }}
            >
              <button
                onMouseEnter={() => setBgColor("orange")}
                onMouseLeave={() => setBgColor("#663300")}
                style={{
                  marginLeft: "43%",
                  backgroundColor,
                  border: "none",
                  color: "white",
                  fontSize: 14,
                  width: 90,
                  paddingLeft: 10,
                  paddingRight: 10,
                  height: 40,
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          width: "100%",
          paddingLeft: "20%",
          paddingRight: "20%",
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            backgroundColor: "#f7f7f7",
            borderRadius: 10,
            marginBottom: 60,
          }}
        >
          <div
            style={{
              flexDirection: "column",
              flex: 1,
              display: "flex",
              marginBottom: 30,
              paddingLeft: 63,
              paddingRight: 63,
            }}
          >
            <h5
              style={{
                fontFamily: "'Jim Nightshade', cursive",
                fontSize: 24,
                color: "#663300",
                textAlign: "center",
                marginTop: 26,
              }}
            >
              Continue as a guest
            </h5>

            <button
              onMouseEnter={() => setContinuecolor("orange")}
              onMouseLeave={() => setContinuecolor("#663300")}
              style={{
                marginLeft: "43%",
                backgroundColor: continuecolor,
                border: "none",
                color: "white",
                fontSize: 14,
                width: 90,
                marginTop: 22,
                paddingLeft: 10,
                paddingRight: 10,
                height: 40,
              }}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;