import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdHome } from "react-icons/md";
import "../Products/ProductDetails.css";
import { FaPhoneAlt } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
import { IoIosSearch } from "react-icons/io";
import { BsFilterLeft } from "react-icons/bs";
import { AiOutlineAppstore } from "react-icons/ai";
import { GoPackage } from "react-icons/go";
import { TiShoppingCart } from "react-icons/ti";
import { FaRupeeSign } from "react-icons/fa";
import { RiCoupon3Line } from "react-icons/ri";
import { FaHandsHelping } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin5Line } from "react-icons/ri";
import { MdOutlineStarOutline } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import { IoCloseOutline } from "react-icons/io5";

const ProductDetails = () => {
  const [quantity, setquantity] = useState(1);

  const handledecrement = () => {
    setquantity((prevCount) => prevCount - 1);
  };

  const handleincrement = () => {
    setquantity((prevCount) => prevCount + 1);
  };

  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("");
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);

    // You can add more conditions or redirect to other pages as needed
    if (selectedValue === "CreateProduct") {
      navigate("/CreateProduct");
    } else if (selectedValue === "ListCoupons") {
      navigate("/ListCoupons");
    }
  };
  return (
    <div>
      <main>
        {/* Header Section*/}
        <section>
          <div className="container-fluid bg-brown txt-gwhite">
            <div className="header-section d-flex flex-wrap">
              <div className="row">
                <div className="col-12">
                  <div className="dashboard-head">
                    <div className="row">
                      <div className="col-6">
                        <div className="admin-head-home txt-left pt-2-5 ">
                          <MdHome />
                        </div>
                        <div className="admin-head-address txt-left f-12 mt-1 ml-10p p-3">
                          <small>
                            3/66 3,ST-22 Kuruvilaikadu,Eraviputhurkadai.
                          </small>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="admin-home-number">
                          <div className="admin-phone-icon f-12 mt-1">
                            <FaPhoneAlt />
                          </div>
                          <div className="admin-phone-number f-10 txt-right">
                            <p>73739 32235</p>
                            <p className="admin-head-num2 ms-5">94880 68189</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Search Section */}
        <section>
          <div className="container-fluid">
            <div className="search-section d-flex flex-wrap txt-center mt-4">
              <div className="row">
                <div className="col-12">
                  <div className="dashboard-search">
                    <div className="row">
                      <div className="col-2">
                        <div className="dashboard-logo">
                          <h3>BISMI.</h3>
                        </div>
                      </div>
                      <div className="col-8">
                        <div className="dashboard-search">
                          <div className="row">
                            <div className="col-2 search-loc">
                              <div className="search-location">
                                <p className="pos-abs mt-1">
                                  <IoLocationOutline />
                                </p>
                                <p className="mt-1">Search</p>
                              </div>
                            </div>
                            <div className="col-8 txt-left">
                              <input
                                className="w-100 h-36"
                                placeholder="Search List.."
                                type="text"
                                name=""
                                id=""
                              />
                            </div>
                            <div className="col-2 txt-left">
                              <button className="search-btn" type="button">
                                <IoIosSearch className="mt--5 txt-gwhite" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="dashboard-profile mt-1">
                          <div className="row">
                            <div className="col">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "assets/images/dash-profile.jpg"
                                }
                                alt=""
                                className="dash-img"
                              />
                            </div>
                            <div className="col txt-left">
                              <p className="f-10 ms-3 w-100 mt-1">
                                Hello,
                                <br />
                                <small className="fw-bold">My Account</small>
                              </p>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Categories Section */}
        <section>
          <div className="container-fluid">
            <div className="categories-section">
              <div className="row">
                <div className="col-12">
                  <div className="categories-select f-14 ml-7p">
                    <div className="row">
                      <div className="col">
                        <div className="filter-cat">
                          <button
                            type="button"
                            className="txt-gwhite b-none bg-brown p-1 br-rad-2"
                          >
                            <BsFilterLeft />
                            <small>All Categories</small>
                          </button>
                        </div>
                      </div>
                      <div className="col">
                        <div className="categories-dash">
                          <button
                            type="button"
                            className=" txt-brown bg-trans b-none"
                          >
                            <AiOutlineAppstore />
                            <small>Dashboard</small>
                          </button>
                        </div>
                      </div>
                      <div className="col flt-left">
                        <div className="categories-products">
                          <button type="button" className="bg-trans b-none">
                            <GoPackage />
                            <select
                              name=""
                              id=""
                              className="b-none bg-trans w-60 f-12"
                              onChange={handleSelectChange}
                              value={selectedOption}
                            >
                              <option value="">Products</option>
                              <option value="">Category</option>
                              <option value="">Product List</option>
                              <option value="">Product Detail</option>
                              <option value="CreateProduct">Add Product</option>
                            </select>
                          </button>
                        </div>
                      </div>
                      <div className="col">
                        <div className="categories-orders flt-left">
                          <button className="b-none bg-trans" type="button">
                            <TiShoppingCart />
                            <select
                              name=""
                              id=""
                              className="b-none bg-trans w-50 f-12"
                              onChange={handleSelectChange}
                              value={selectedOption}
                            >
                              <option value="">Orders</option>
                              <option value="">Order List</option>
                              <option value="">Order Tracking</option>
                              <option value="">Order Details</option>
                            </select>
                          </button>
                        </div>
                      </div>
                      <div className="col">
                        <div className="categories-accounts">
                          <button type="button" className="b-none bg-trans">
                            <FaRupeeSign />
                            <select
                              name=""
                              id=""
                              className="b-none bg-trans w-70 f-12"
                              onChange={handleSelectChange}
                              value={selectedOption}
                            >
                              <option value="">Accounts</option>
                              <option value="">Order</option>
                              <option value="">Transactions</option>
                            </select>
                          </button>
                        </div>
                      </div>
                      <div className="col coupons-left">
                        <div className="categories-coupons">
                          <button type="button" className="b-none bg-trans">
                            <RiCoupon3Line />
                            <select
                              name=""
                              id=""
                              className="b-none bg-trans w-60 f-12"
                              onChange={handleSelectChange}
                              value={selectedOption}
                            >
                              <option value="">Coupons</option>
                              <option value="ListCoupons">List Coupons</option>
                              <option value="">Create Coupons</option>
                            </select>
                          </button>
                        </div>
                      </div>
                      <div className="col">
                        <div className="today-orders flt-right">
                          <button
                            type="button"
                            className="tdy-orders-bg b-none txt-gwhite hgt-28 br-rad-2 f-12"
                          >
                            <FaHandsHelping />
                            Today Orders
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Bismi Admin Panel */}
        <section>
          <div className="container-fluid Admin-pnl-head-bg mt-3">
            <div className="row">
              <div className="col-12">
                <div className="ps-7p">
                  <h6 className="mt-2 txt-brown">PRODUCT DETAIL</h6>
                  <p className="f-10">Bismi Admin Panel</p>
                </div>
              </div>
            </div>
          </div>
        </section>



        
        {/* Edit Modal */}
        <div
          class="modal fade"
          id="editreviewModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog f-12">
            <div class="modal-content w-80 ms-5 margin-top-25p">
              <div class="modal-header">
                <h6 class="modal-title" id="exampleModalLabel">
                  Review Edit
                </h6>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
              <div class="mb-3">
                <p><img className="w-40 h-10p" src={
                              process.env.PUBLIC_URL +
                              "assets/images/cashew.jpeg"
                            } alt="" /></p>
                  <p>Scoott Barber</p>
                </div>
                
                
                <div class="mb-3">
                  <input
                    type="text"
                    className="form-control f-12"
                    id=""
                    placeholder=""
                    value="5 star"
                  />
                </div>
                <div class="mb-3">
                  <textarea className="form-control h-10 f-12" name="" id="" cols="60" rows="3"  value="Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi assumenda nostrum debitis, illo eaque ut minus eius similique"></textarea>
                  
                </div>






              </div>
              <div class="modal-footer ">
                
                <button
                  type="button"
                  class="bg-brown b-none w-20 txt-gwhite p-1 br-rad-2 margin-right-10p"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>


           {/* Delete Modal */}
           <div
          class="modal fade"
          id="deletereviewModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog f-12">
            <div class="modal-content margin-top-50p w-80 ms-5">
              <div class="modal-body">
                <div className="close-icons mb-4">
                  <button
                    type="button"
                    className="flt-right bg-white b-none"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <IoCloseOutline className="f-16" />
                  </button>
                </div>
                <div className="delete-icon txt-center">
                  <AiFillDelete className="delete-modal-icon" />
                </div>
                <div className="delete-content text-center">
                  <p className="fw-bold">Are you Sure?</p>
                  <p>Are you sure you want to remove this review list</p>
                </div>
                <div className="delete-buttons txt-center">
                  <button
                    type="button"
                    class="me-2 mt-2 mb-4 bg-grey b-none txt-gwhite br-rad-2 p-1 f-12 w-10"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    class="bg-brown b-none txt-gwhite br-rad-2 p-1 f-12 w-30 txt-center"
                  >
                    Yes! Delete it
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Product Details */}
        <section>
          <div className="container-fluid d-flex flex-wrap f-12">
            <div className="row">
              <div className="col-12">
                <div className="product-detail-section mt-4">
                  <div className="row">
                    <div className="col-4">
                      <div className="product-detail-img-sec">
                        <div className="prod-img">
                          <img
                            className="w-70 h-40 ml-24p mt-3"
                            src={
                              process.env.PUBLIC_URL +
                              "assets/images/special-gal-08.png"
                            }
                            alt=""
                            srcset=""
                          />
                        </div>
                        <div className="prod-price ml-24p f-14">
                          <p>Badam</p>
                          <p>Price : Rs 900.00</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="product-detail-cnt">
                        <div className="product-det-head border-bottom mb-2">
                          <p className="f-14 fw-bold">Product Details</p>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit. Nemo ratione delectus nostrum est veniam nihil
                            officia eligendi voluptas! Illo debitis omnis harum
                            obcaecati placeat iusto dignissimos maxime molestiae
                            accusantium rerum.
                          </p>
                        </div>
                        <div className="product-price-det border-bottom">
                          <div className="row">
                            <div className="col-6">
                              <p>
                                Price:{" "}
                                <span className="txt-brown fw-bold">Rs.900.00</span>
                              </p>
                              <p>Type: Badam</p>

                              <div className="input-group w-40">
                                <p>Quantity:</p>
                                <button
                                  type="button"
                                  className="bg-white w-10 h-20 br-grey padding-6"
                                  onClick={handledecrement}
                                >
                                  -
                                </button>
                                <div className="form-control f-12 txt-center">{quantity}</div>
                                <button
                                  type="button"
                                  className="bg-white w-10 h-20 br-grey padding-6"
                                  onClick={handleincrement}
                                >
                                  +
                                </button>
                              </div>

                              <p>Product Category: Nuts</p>
                            </div>
                            <div className="col-6">
                              <p>
                                Size:
                                <div
                                  class="btn-group ms-2"
                                  role="group"
                                  aria-label="Basic example"
                                >
                                  <button
                                    type="button"
                                    className="w-40 bg-none"
                                  >
                                    5kg
                                  </button>
                                  <button
                                    type="button"
                                    className="w-40 bg-none"
                                  >
                                    3kg
                                  </button>
                                  <button
                                    type="button"
                                    className="w-40 bg-none"
                                  >
                                    2kg
                                  </button>
                                </div>
                              </p>
                              <p>
                                Availability:{" "}
                                <span className="txt-green">In Stock!</span>
                              </p>
                              <p>
                                Rating: <MdOutlineStarOutline />
                                <MdOutlineStarOutline />
                                <MdOutlineStarOutline />
                                <MdOutlineStarOutline />
                                <MdOutlineStarOutline />
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="product-review-section mt-3 ms-3">
                          <div className="row">
                            <div className="col-6">
                              <h6>Review</h6>
                            </div>
                            <div className="col-6">
                              <p className="flt-right f-14">
                              <button
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#editreviewModal"
                              className="clr-lgt-bul f-14 bg-white b-none"
                            >
                                <CiEdit className="me-4" /></button>
                                <button
                              type="button"
                              className="clr-rd f-14 bg-white b-none"
                              data-bs-toggle="modal"
                              data-bs-target="#deletereviewModal"
                            ><RiDeleteBin5Line className="txt-red" /></button>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="product-review-contact border-bottom mb-10 ms-2">
                          <div className="row">
                            <div className="col-12">
                              <div className="pro-cnt">
                                <div className="review-contact-img">
                                  <img
                                    className="w-10 ms-2"
                                    src={
                                      process.env.PUBLIC_URL +
                                      "assets/images/cashew.jpeg"
                                    }
                                    alt=""
                                    srcset=""
                                  />
                                </div>
                                <div className="review-contact-rating">
                                  <p>Scoot Barber</p>
                                  <p>
                                    <MdOutlineStarOutline />
                                    <MdOutlineStarOutline />
                                    <MdOutlineStarOutline />
                                    <MdOutlineStarOutline />
                                    <MdOutlineStarOutline />
                                  </p>
                                </div>
                                <div className="review-contact-description">
                                  <p>
                                    Lorem ipsum dolor sit amet consectetur
                                    adipisicing elit. Facere et expedita vero
                                    cumque natus soluta asperiores! Animi vel
                                    minus deserunt, enim amet consequuntur
                                    earum! Officiis dolor explicabo totam
                                    adipisci ipsam!
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default ProductDetails;
