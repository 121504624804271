import React from "react";
import { IoMdHome } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaPinterest } from "react-icons/fa";
import logo2 from "./assets/images/logo2.png";
import "./Footer.css";

const Footer = () => {
  return (
    <div>
      {/* Footer Section */}
      <section className="mt-8p">
        <div className="container-fluid bg-lgt-brown d-flex flex-wrap">
          <div className="row" style={{ paddingBottom: "1%" }}>
            <div
              className="col-3 "
              style={{ paddingLeft: "5%", marginTop: "5%" }}
            >
              <img
                src={logo2}
                alt=""
                style={{ width: "60%", height: "auto" }}
              />
            </div>
            <div className="col-4 mt-5 ">
              <div className="footer-support-section ">
                <h4 className="txt-yellow f-33 ms-5">Our Address</h4>
                <div className="footer-address-section ms-5">
                  <div className="form-group txt-gwhite f-14">
                    <p className="footer-addicon">
                      <IoMdHome />
                    </p>
                    <p className="ms-5 f-fbody">
                      Eraviputhurkadai, Kanyakumari dist, Tamil Nadu, India
                    </p>
                  </div>
                  <div className="form-group txt-gwhite f-14">
                    <p className="footer-phoneicon">
                      <FaPhoneAlt />
                    </p>
                    <p className="ms-5 f-fbody">+91 7373932235</p>
                  </div>
                  <div className="footer-address-icons mt-5 ms-4">
                    <button className="icons b-none">
                      <FaFacebookF />
                    </button>
                    <button className="icons ms-2 b-none">
                      <FaInstagram />
                    </button>
                    <button className="icons ms-2 b-none">
                      <FaTwitter />
                    </button>
                    <button className="icons ms-2 b-none">
                      <FaPinterest />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3 mt-5">
              <div className="footer-Info-section ml-48p">
                <h4 className="txt-yellow f-33">View Location</h4>
                <div className="footer-Info-body txt-gwhite f-16 f-fbody">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d75121.94964539463!2d77.17876264543385!3d8.291737760912103!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1711105189664!5m2!1sen!2sin"
                    width="350"
                    height="200"
                    style={{ border: "0" }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer;
