import React from "react";
import { MdHome } from "react-icons/md";
import "../Products/EditProduct.css";
import { FaPhoneAlt } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
import { IoIosSearch } from "react-icons/io";
import { BsFilterLeft } from "react-icons/bs";
import { AiOutlineAppstore } from "react-icons/ai";
import { GoPackage } from "react-icons/go";
import { TiShoppingCart } from "react-icons/ti";
import { FaRupeeSign } from "react-icons/fa";
import { RiCoupon3Line } from "react-icons/ri";
import { FaHandsHelping } from "react-icons/fa";
import { TfiPackage } from "react-icons/tfi";
import { MdFileUpload } from "react-icons/md";
import { MdOutlineImage } from "react-icons/md";

const EditProduct = () => {
  return (
    <div>
      <main>
        {/* Header Section*/}
        <section>
          <div className="container-fluid bg-brown txt-gwhite">
            <div className="header-section d-flex flex-wrap">
              <div className="row">
                <div className="col-12">
                  <div className="dashboard-head">
                    <div className="row">
                      <div className="col-6">
                        <div className="admin-head-home txt-left pt-2-5 ">
                          <MdHome />
                        </div>
                        <div className="admin-head-address txt-left f-12 mt-1 ml-10p p-3">
                          <small>
                            3/66 3,ST-22 Kuruvilaikadu,Eraviputhurkadai.
                          </small>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="admin-home-number">
                          <div className="admin-phone-icon f-12 mt-1">
                            <FaPhoneAlt />
                          </div>
                          <div className="admin-phone-number f-10 txt-right">
                            <p>73739 32235</p>
                            <p className="admin-head-num2 ms-5">94880 68189</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Search Section */}
        <section>
          <div className="container-fluid">
            <div className="search-section d-flex flex-wrap txt-center mt-4">
              <div className="row">
                <div className="col-12">
                  <div className="dashboard-search">
                    <div className="row">
                      <div className="col-2">
                        <div className="dashboard-logo">
                          <h3>BISMI.</h3>
                        </div>
                      </div>
                      <div className="col-8">
                        <div className="dashboard-search">
                          <div className="row">
                            <div className="col-2 search-loc">
                              <div className="search-location">
                                <p className="pos-abs mt-1">
                                  <IoLocationOutline />
                                </p>
                                <p className="mt-1">Search</p>
                              </div>
                            </div>
                            <div className="col-8 txt-left">
                              <input
                                className="w-100 h-36"
                                placeholder="Search List.."
                                type="text"
                                name=""
                                id=""
                              />
                            </div>
                            <div className="col-2 txt-left">
                              <button className="search-btn" type="button">
                                <IoIosSearch className="mt--5 txt-gwhite" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="dashboard-profile mt-1">
                          <div className="row">
                            <div className="col">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "assets/images/dash-profile.jpg"
                                }
                                alt=""
                                className="dash-img"
                              />
                            </div>
                            <div className="col txt-left">
                              <p className="f-10 ms-3 w-100 mt-1">
                                Hello,
                                <br />
                                <small className="fw-bold">My Account</small>
                              </p>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Categories Section */}
        <section>
          <div className="container-fluid">
            <div className="categories-section">
              <div className="row">
                <div className="col-12">
                  <div className="categories-select f-14 ml-7p">
                    <div className="row">
                      <div className="col">
                        <div className="filter-cat">
                          <button
                            type="button"
                            className="txt-gwhite b-none bg-brown p-1 br-rad-2"
                          >
                            <BsFilterLeft />
                            <small>All Categories</small>
                          </button>
                        </div>
                      </div>
                      <div className="col">
                        <div className="categories-dash">
                          <button
                            type="button"
                            className=" txt-brown bg-trans b-none"
                          >
                            <AiOutlineAppstore />
                            <small>Dashboard</small>
                          </button>
                        </div>
                      </div>
                      <div className="col flt-left">
                        <div className="categories-products">
                          <button type="button" className="bg-trans b-none">
                            <GoPackage />
                            <select
                              name=""
                              id=""
                              className="b-none bg-trans w-60 f-12"
                            >
                              <option value="">Products</option>
                              <option value="">Category</option>
                              <option value="">Product List</option>
                              <option value="">Product Detail</option>
                              <option value="">Add Product</option>
                            </select>
                          </button>
                        </div>
                      </div>
                      <div className="col">
                        <div className="categories-orders flt-left">
                          <button className="b-none bg-trans" type="button">
                            <TiShoppingCart />
                            <select
                              name=""
                              id=""
                              className="b-none bg-trans w-50 f-12"
                            >
                              <option value="">Orders</option>
                              <option value="">Order List</option>
                              <option value="">Order Tracking</option>
                              <option value="">Order Details</option>
                            </select>
                          </button>
                        </div>
                      </div>
                      <div className="col">
                        <div className="categories-accounts">
                          <button type="button" className="b-none bg-trans">
                            <FaRupeeSign />
                            <select
                              name=""
                              id=""
                              className="b-none bg-trans w-70 f-12"
                            >
                              <option value="">Accounts</option>
                              <option value="">Order</option>
                              <option value="">Transactions</option>
                            </select>
                          </button>
                        </div>
                      </div>
                      <div className="col coupons-left">
                        <div className="categories-coupons">
                          <button type="button" className="b-none bg-trans">
                            <RiCoupon3Line />
                            <select
                              name=""
                              id=""
                              className="b-none bg-trans w-60 f-12"
                            >
                              <option value="">Coupons</option>
                              <option value="">List Coupons</option>
                              <option value="">Create Coupons</option>
                            </select>
                          </button>
                        </div>
                      </div>
                      <div className="col">
                        <div className="today-orders flt-right">
                          <button
                            type="button"
                            className="tdy-orders-bg b-none txt-gwhite hgt-28 br-rad-2 f-12"
                          >
                            <FaHandsHelping />
                            Today Orders
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Bismi Admin Panel */}
        <section>
          <div className="container-fluid Admin-pnl-head-bg mt-3">
            <div className="row">
              <div className="col-12">
                <div className="ps-7p">
                  <h6 className="mt-2 txt-brown">EDIT PRODUCTS</h6>
                  <p className="f-10">Bismi Admin Panel</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Create Product Section */}
        <section>
          <div className="container-fluid d-flex flex-wrap">
            <div className="row">
              <div className="col-12">
                <div className="crt-product-sec">
                  <div className="crt-prdt-tit">
                    <p className="f-12 ml-7p mt-3 bt-3 fw-bold ltr_spacing-05">
                      EDIT PRODUCT
                    </p>
                  </div>
                  <div className="fill-info pt-2 pb-2">
                    <div className="row">
                      <div className="col-1 mt-1 ms-1">
                        <div className="fill-info-icon">
                          <TfiPackage className="txt-gwhite ms-12-5 mt-2 f-18" />
                        </div>
                      </div>
                      <div className="col-2 flt-left">
                        <div className="fill-info-cnt">
                          <p className="fw-bold">Product Information</p>
                          <p>Fill all Information below</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <form action="" method="post">
                    <div className="product-frm border-black pt-2 pb-2">
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group ml-6p">
                            <label htmlFor="" className="f-12">
                              Product title
                            </label>
                            <input
                              className="form-control f-12 h-10 mt-3 bx-shadow-input mb-3"
                              type="text"
                              name=""
                              id=""
                              placeholder="Enter product title"
                            />
                          </div>
                          <div className="form-group ml-6p">
                            <label htmlFor="" className="f-12">
                              Product Description
                            </label>
                            <textarea
                              className="form-control f-12 h-10 mt-3 bx-shadow-input mb-3"
                              cols="10"
                              rows="4"
                            ></textarea>
                          </div>
                          <div className="form-group ml-6p">
                            <label htmlFor="" className="f-12">
                              Product title
                            </label>
                            <select className="form-control f-12 h-10 mt-3 bx-shadow-input">
                              <option>Dates</option>
                              <option>Nuts</option>
                              <option>Dry Fruits</option>
                              <option>Chocolates</option>
                              <option>category Name</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="fill-info mt-3 pt-2 pb-2">
                      <div className="row">
                        <div className="col-1 mt-1 ms-1">
                          <div className="fill-info-icon">
                            <MdOutlineImage className="txt-gwhite ms-12-5 mt-2 f-18" />
                          </div>
                        </div>
                        <div className="col-2 flt-left">
                          <div className="fill-info-cnt">
                            <p className="fw-bold">Product Image</p>
                            <p>Add Product Gallery image</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="product-frm border-black">
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group ml-7p mt-3 pt-2 pb-2 border-dotted">
                            <div class="form-group file-area">
                              <label for="images" className="f-12">
                                Images{" "}
                                <span className="f-12">
                                  Your images should be at least 400x300 wide
                                </span>
                              </label>
                              <input
                                type="file"
                                name="images"
                                id="images"
                                required="required"
                                multiple="multiple"
                              />
                              <div class="file-dummy">
                                <div class="success">
                                  Great, your files are selected. Keep on.
                                </div>
                                <div className="Image-Upload-icon">
                                  <MdFileUpload className="" />
                                </div>
                                <div class="default f-12">
                                  Drop Files Here or click to upload image
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fill-info mt-3 pt-2 pb-2">
                      <div className="row">
                        <div class="col-3">
                          <div class="form-group f-12">
                            <label>Product Size</label>
                            <input
                              placeholder="Quantity"
                              type="text"
                              className="form-control f-12"
                            />
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="form-group f-12">
                            <label>Price</label>
                            <div className="input-group">
                              <span
                                className="input-group-text f-12"
                                id="basic-addon1"
                              >
                                ₹
                              </span>
                              <input
                                type="text"
                                className="form-control f-12"
                                placeholder="Enter Price"
                                aria-label="Enter Price"
                                aria-describedby="basic-addon1"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="form-group f-12">
                            <label>Discount</label>
                            <div className="input-group">
                              <span
                                className="input-group-text f-12"
                                id="basic-addon1"
                              >
                                %
                              </span>
                              <input
                                type="text"
                                className="form-control f-12"
                                placeholder="Enter discount amount"
                                aria-label="Enter discount amount"
                                aria-describedby="basic-addon1"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="col-3">
                          <div class="form-group f-12 ">
                            <label>Availabilty</label>
                            <select className="form-control f-12">
                              <option>Select Availabilty</option>
                              <option value="">Stock</option>
                              <option value="">No Stock</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="prod-btn-sec">
                      <button className="crt-prod-btn">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default EditProduct;
