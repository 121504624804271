import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdHome } from "react-icons/md";
import "../Coupons/CreateCoupons.css";
import { FaPhoneAlt } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
import { IoIosSearch } from "react-icons/io";
import { BsFilterLeft } from "react-icons/bs";
import { AiOutlineAppstore } from "react-icons/ai";
import { GoPackage } from "react-icons/go";
import { TiShoppingCart } from "react-icons/ti";
import { FaRupeeSign } from "react-icons/fa";
import { RiCoupon3Line } from "react-icons/ri";
import { FaHandsHelping } from "react-icons/fa";

const CreateCoupons = () => {
  const navigate = useNavigate();
  const [selectedMenu, setSelectedMenu] = useState("General");

  const handleMenuClick = (menu) => {
    setSelectedMenu(menu);
  };

  const [selectedOption, setSelectedOption] = useState("");

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);

    // You can add more conditions or redirect to other pages as needed
    if (selectedValue === "CreateProduct") {
      navigate("/CreateProduct");
    }
  };
  return (
    <div>
      <main>
        {/* Header Section*/}
        <section>
          <div className="container-fluid bg-brown txt-gwhite">
            <div className="header-section d-flex flex-wrap">
              <div className="row">
                <div className="col-12">
                  <div className="dashboard-head">
                    <div className="row">
                      <div className="col-6">
                        <div className="admin-head-home txt-left pt-2-5 ">
                          <MdHome />
                        </div>
                        <div className="admin-head-address txt-left f-12 mt-1 ml-10p p-3">
                          <small>
                            3/66 3,ST-22 Kuruvilaikadu,Eraviputhurkadai.
                          </small>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="admin-home-number">
                          <div className="admin-phone-icon f-12 mt-1">
                            <FaPhoneAlt />
                          </div>
                          <div className="admin-phone-number f-10 txt-right">
                            <p>73739 32235</p>
                            <p className="admin-head-num2 ms-5">94880 68189</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Search Section */}
        <section>
          <div className="container-fluid">
            <div className="search-section d-flex flex-wrap txt-center mt-4">
              <div className="row">
                <div className="col-12">
                  <div className="dashboard-search">
                    <div className="row">
                      <div className="col-2">
                        <div className="dashboard-logo">
                          <h3>BISMI.</h3>
                        </div>
                      </div>
                      <div className="col-8">
                        <div className="dashboard-search">
                          <div className="row">
                            <div className="col-2 search-loc">
                              <div className="search-location">
                                <p className="pos-abs mt-1">
                                  <IoLocationOutline />
                                </p>
                                <p className="mt-1">Search</p>
                              </div>
                            </div>
                            <div className="col-8 txt-left">
                              <input
                                className="w-100 h-36"
                                placeholder="Search List.."
                                type="text"
                                name=""
                                id=""
                              />
                            </div>
                            <div className="col-2 txt-left">
                              <button className="search-btn" type="button">
                                <IoIosSearch className="mt--5 txt-gwhite" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="dashboard-profile mt-1">
                          <div className="row">
                            <div className="col">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "assets/images/dash-profile.jpg"
                                }
                                alt=""
                                className="dash-img"
                              />
                            </div>
                            <div className="col txt-left">
                              <p className="f-10 ms-3 w-100 mt-1">
                                Hello,
                                <br />
                                <small className="fw-bold">My Account</small>
                              </p>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Categories Section */}
        <section>
          <div className="container-fluid">
            <div className="categories-section">
              <div className="row">
                <div className="col-12">
                  <div className="categories-select f-14 ml-7p">
                    <div className="row">
                      <div className="col">
                        <div className="filter-cat">
                          <button
                            type="button"
                            className="txt-gwhite b-none bg-brown p-1 br-rad-2"
                          >
                            <BsFilterLeft />
                            <small>All Categories</small>
                          </button>
                        </div>
                      </div>
                      <div className="col">
                        <div className="categories-dash">
                          <button
                            type="button"
                            className=" txt-brown bg-trans b-none"
                          >
                            <AiOutlineAppstore />
                            <small>Dashboard</small>
                          </button>
                        </div>
                      </div>
                      <div className="col flt-left">
                        <div className="categories-products">
                          <button type="button" className="bg-trans b-none">
                            <GoPackage />
                            <select
                              name=""
                              id=""
                              className="b-none bg-trans w-60 f-12"
                              onChange={handleSelectChange}
                              value={selectedOption}
                            >
                              <option value="">Products</option>
                              <option value="">Category</option>
                              <option value="">Product List</option>
                              <option value="">Product Detail</option>
                              <option value="CreateProduct">Add Product</option>
                            </select>
                          </button>
                        </div>
                      </div>
                      <div className="col">
                        <div className="categories-orders flt-left">
                          <button className="b-none bg-trans" type="button">
                            <TiShoppingCart />
                            <select
                              name=""
                              id=""
                              className="b-none bg-trans w-50 f-12"
                            >
                              <option value="">Orders</option>
                              <option value="">Order List</option>
                              <option value="">Order Tracking</option>
                              <option value="">Order Details</option>
                            </select>
                          </button>
                        </div>
                      </div>
                      <div className="col">
                        <div className="categories-accounts">
                          <button type="button" className="b-none bg-trans">
                            <FaRupeeSign />
                            <select
                              name=""
                              id=""
                              className="b-none bg-trans w-70 f-12"
                            >
                              <option value="">Accounts</option>
                              <option value="">Order</option>
                              <option value="">Transactions</option>
                            </select>
                          </button>
                        </div>
                      </div>
                      <div className="col coupons-left">
                        <div className="categories-coupons">
                          <button type="button" className="b-none bg-trans">
                            <RiCoupon3Line />
                            <select
                              name=""
                              id=""
                              className="b-none bg-trans w-60 f-12"
                            >
                              <option value="">Coupons</option>
                              <option value="">List Coupons</option>
                              <option value="">Create Coupons</option>
                            </select>
                          </button>
                        </div>
                      </div>
                      <div className="col">
                        <div className="today-orders flt-left ms-5">
                          <button
                            type="button"
                            className="tdy-orders-bg b-none txt-gwhite hgt-28 br-rad-2 f-12"
                          >
                            <FaHandsHelping />
                            Today Orders
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Bismi Admin Panel */}
        <section>
          <div className="container-fluid Admin-pnl-head-bg mt-3">
            <div className="row">
              <div className="col-12">
                <div className="ps-7p">
                  <h6 className="mt-2 txt-brown">CREATE COUPON</h6>
                  <p className="f-10">Bismi Admin Panel</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Create Coupon Form */}
        <section>
          <div className="create-section-body form-bg mt-5 mb-5 w-60 p-5 ml-20p">
            <form action="" method="post">
              <div className="row">
                <div className="col-12">
                  <div className="form">
                    <div className="form-group p-2 mt-3 f-12">
                      <div className="row">
                        <div className="col-12">
                          <div className="crt-coupons-menus">
                            <ul className="ul-menu">
                              <li
                                className={`menu-item ${
                                  selectedMenu === "General" ? "active" : ""
                                }`}
                                onClick={() => handleMenuClick("General")}
                              >
                                General
                                {selectedMenu === "General" && (
                                  <hr className="menu-divider" />
                                )}
                              </li>
                              <li
                                className={`menu-item ${
                                  selectedMenu === "Restriction" ? "active" : ""
                                }`}
                                onClick={() => handleMenuClick("Restriction")}
                              >
                                Restriction
                                {selectedMenu === "Restriction" && (
                                  <hr className="menu-divider" />
                                )}
                              </li>
                              <li
                                className={`menu-item ${
                                  selectedMenu === "Usage" ? "active" : ""
                                }`}
                                onClick={() => handleMenuClick("Usage")}
                              >
                                Usage
                                {selectedMenu === "Usage" && (
                                  <hr className="menu-divider" />
                                )}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      {/* General Info */}
                    </div>
                    {selectedMenu === "General" && (
                      <div class="general-info-cnt">
                        <div className="row">
                          <div className="col-3 mb-3">
                            <label
                              htmlFor=""
                              className="f-12 ms-2 fw-bold txt-brown"
                            >
                              General
                            </label>
                          </div>
                        </div>
                        <div className="form-group p-2 f-12">
                          <div className="row">
                            <div className="col-3">
                              <label htmlFor="" className="mt-1">
                                Coupon Title
                                <span style={{ color: "red" }}>*</span>
                              </label>
                            </div>
                            <div className="col-9">
                              <input
                                className="form-control h-100 f-14"
                                type="text"
                                name="fstname"
                                id="fstname"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group p-2 f-12">
                          <div className="row">
                            <div className="col-3">
                              <label htmlFor="" className="mt-1">
                                Coupon Code
                                <span style={{ color: "red" }}>*</span>
                              </label>
                            </div>
                            <div className="col-9">
                              <input
                                className="form-control h-100 f-14"
                                type="text"
                                name="fstname"
                                id="fstname"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group p-2 mt-3 f-12">
                          <div className="row">
                            <div className="col-3">
                              <label htmlFor="" className="mt-1">
                                Start Date
                              </label>
                            </div>
                            <div className="col-9">
                              <input
                                className="form-control h-100 f-14"
                                type="date"
                                name="fstname"
                                id="fstname"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group p-2 mt-3 f-12">
                          <div className="row">
                            <div className="col-3">
                              <label htmlFor="" className="mt-1">
                                End Date
                              </label>
                            </div>
                            <div className="col-9">
                              <input
                                className="form-control h-100 f-14"
                                type="date"
                                name="fstname"
                                id="fstname"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group p-2 mt-3 f-12">
                          <div className="row">
                            <div className="col-3">
                              <label htmlFor="" className="mt-1">
                                Free Shipping
                              </label>
                            </div>
                            <div className="col-9">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckDefault"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault"
                                >
                                  Allow Free Shipping
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-group p-2 mt-3 f-12">
                          <div className="row">
                            <div className="col-3">
                              <label htmlFor="" className="mt-1">
                                Quantity
                              </label>
                            </div>
                            <div className="col-9">
                              <input
                                className="form-control h-100 f-14"
                                type="text"
                                name="fstname"
                                id="fstname"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group p-2 mt-3 f-12">
                          <div className="row">
                            <div className="col-3">
                              <label htmlFor="" className="mt-1">
                                Discount Type
                              </label>
                            </div>
                            <div className="col-9">
                              <select
                                name=""
                                id=""
                                className="form-control h-100 f-12"
                              >
                                <option value=""></option>
                                <option value="">Percentage</option>
                                <option value="">Fixed</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="form-group p-2 mt-3 f-12">
                          <div className="row">
                            <div className="col-3">
                              <label htmlFor="" className="mt-1">
                                Status
                              </label>
                            </div>
                            <div className="col-9">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckDefault"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault"
                                >
                                  Enable the Coupon
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* Restriction Section */}
                    {selectedMenu === "Restriction" && (
                      <div className="restriction-section">
                        <div className="row">
                          <div className="col-3 mb-3 f-12">
                            <label
                              htmlFor=""
                              className="f-12 ms-2 fw-bold txt-brown"
                            >
                              Restriction
                            </label>
                          </div>
                        </div>

                        <div className="form-group p-2 f-12">
                          <div className="row">
                            <div className="col-3">
                              <label htmlFor="" className="mt-1">
                                Products
                              </label>
                            </div>
                            <div className="col-9">
                              <input
                                className="form-control h-100 f-14"
                                type="text"
                                name="fstname"
                                id="fstname"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="form-group p-2 mt-3 f-12">
                          <div className="row">
                            <div className="col-3">
                              <label htmlFor="" className="mt-1">
                                Category
                              </label>
                            </div>
                            <div className="col-9">
                              <select
                                name=""
                                id=""
                                className="form-control h-100 f-12"
                              >
                                <option value="">Select</option>
                                <option value="">Dates</option>
                                <option value="">Nuts</option>
                                <option value="">Dry Fruits</option>
                                <option value="">Chocolates</option>
                                <option value="">Category Name</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="form-group p-2 mt-3 f-12">
                          <div className="row">
                            <div className="col-3">
                              <label htmlFor="" className="mt-1">
                                Minimum Spend
                              </label>
                            </div>
                            <div className="col-9">
                              <input
                                className="form-control h-100 f-14"
                                type="text"
                                name="fstname"
                                id="fstname"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="form-group p-2 mt-3 f-12">
                          <div className="row">
                            <div className="col-3">
                              <label htmlFor="" className="mt-1">
                                Maximum Spend
                              </label>
                            </div>
                            <div className="col-9">
                              <input
                                className="form-control h-100 f-14"
                                type="text"
                                name="fstname"
                                id="fstname"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* Usage Section */}
                    {selectedMenu === "Usage" && (
                      <div className="usage-section">
                        <div className="row">
                          <div className="col-3 mb-3 f-12">
                            <label
                              htmlFor=""
                              className="f-12 ms-2 fw-bold txt-brown"
                            >
                              Usage
                            </label>
                          </div>
                        </div>

                        <div className="form-group p-2 f-12">
                          <div className="row">
                            <div className="col-3">
                              <label htmlFor="" className="mt-1">
                                Per Limit
                              </label>
                            </div>
                            <div className="col-9">
                              <input
                                className="form-control h-100 f-14"
                                type="text"
                                name="fstname"
                                id="fstname"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="form-group p-2 mt-3 f-12">
                          <div className="row">
                            <div className="col-3">
                              <label htmlFor="" className="mt-1">
                                Per Customer
                              </label>
                            </div>
                            <div className="col-9">
                              <input
                                className="form-control h-100 f-14"
                                type="text"
                                name="fstname"
                                id="fstname"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="btn-section ms-3 mt-2">
                <div className="row">
                  <div className="col-12">
                    <button
                      type="button"
                      className="w-10 bg-brown b-none txt-gwhite pb-1 br-rad-2 flt-right me-5"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
      </main>
    </div>
  );
};

export default CreateCoupons;
