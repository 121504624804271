import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdHome } from "react-icons/md";
import "../Products/Category.css";
import { FaPhoneAlt } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
import { IoIosSearch } from "react-icons/io";
import { BsFilterLeft } from "react-icons/bs";
import { AiOutlineAppstore } from "react-icons/ai";
import { GoPackage } from "react-icons/go";
import { TiShoppingCart } from "react-icons/ti";
import { FaRupeeSign } from "react-icons/fa";
import { RiCoupon3Line } from "react-icons/ri";
import { FaHandsHelping } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { AiFillDelete } from "react-icons/ai";
import { IoCloseOutline } from "react-icons/io5";

const Category = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("");

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);

    // You can add more conditions or redirect to other pages as needed
    if (selectedValue === "CreateProduct") {
      navigate("/CreateProduct");
    }
  };
  return (
    <div>
      <main>
        {/* Header Section*/}
        <section>
          <div className="container-fluid bg-brown txt-gwhite">
            <div className="header-section d-flex flex-wrap">
              <div className="row">
                <div className="col-12">
                  <div className="dashboard-head">
                    <div className="row">
                      <div className="col-6">
                        <div className="admin-head-home txt-left pt-2-5 ">
                          <MdHome />
                        </div>
                        <div className="admin-head-address txt-left f-12 mt-1 ml-10p p-3">
                          <small>
                            3/66 3,ST-22 Kuruvilaikadu,Eraviputhurkadai.
                          </small>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="admin-home-number">
                          <div className="admin-phone-icon f-12 mt-1">
                            <FaPhoneAlt />
                          </div>
                          <div className="admin-phone-number f-10 txt-right">
                            <p>73739 32235</p>
                            <p className="admin-head-num2 ms-5">94880 68189</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Search Section */}
        <section>
          <div className="container-fluid">
            <div className="search-section d-flex flex-wrap txt-center mt-4">
              <div className="row">
                <div className="col-12">
                  <div className="dashboard-search">
                    <div className="row">
                      <div className="col-2">
                        <div className="dashboard-logo">
                          <h3>BISMI.</h3>
                        </div>
                      </div>
                      <div className="col-8">
                        <div className="dashboard-search">
                          <div className="row">
                            <div className="col-2 search-loc">
                              <div className="search-location">
                                <p className="pos-abs mt-1">
                                  <IoLocationOutline />
                                </p>
                                <p className="mt-1">Search</p>
                              </div>
                            </div>
                            <div className="col-8 txt-left">
                              <input
                                className="w-100 h-36"
                                placeholder="Search List.."
                                type="text"
                                name=""
                                id=""
                              />
                            </div>
                            <div className="col-2 txt-left">
                              <button className="search-btn" type="button">
                                <IoIosSearch className="mt--5 txt-gwhite" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="dashboard-profile mt-1">
                          <div className="row">
                            <div className="col">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "assets/images/dash-profile.jpg"
                                }
                                alt=""
                                className="dash-img"
                              />
                            </div>
                            <div className="col txt-left">
                              <p className="f-10 ms-3 w-100 mt-1">
                                Hello,
                                <br />
                                <small className="fw-bold">My Account</small>
                              </p>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Categories Section */}
        <section>
          <div className="container-fluid">
            <div className="categories-section">
              <div className="row">
                <div className="col-12">
                  <div className="categories-select f-14 ml-7p">
                    <div className="row">
                      <div className="col">
                        <div className="filter-cat">
                          <button
                            type="button"
                            className="txt-gwhite b-none bg-brown p-1 br-rad-2"
                          >
                            <BsFilterLeft />
                            <small>All Categories</small>
                          </button>
                        </div>
                      </div>
                      <div className="col">
                        <div className="categories-dash">
                          <button
                            type="button"
                            className=" txt-brown bg-trans b-none"
                          >
                            <AiOutlineAppstore />
                            <small>Dashboard</small>
                          </button>
                        </div>
                      </div>
                      <div className="col flt-left">
                        <div className="categories-products">
                          <button type="button" className="bg-trans b-none">
                            <GoPackage />
                            <select
                              name=""
                              id=""
                              className="b-none bg-trans w-60 f-12"
                              onChange={handleSelectChange}
                              value={selectedOption}
                            >
                              <option value="">Products</option>
                              <option value="">Category</option>
                              <option value="">Product List</option>
                              <option value="">Product Detail</option>
                              <option value="CreateProduct">Add Product</option>
                            </select>
                          </button>
                        </div>
                      </div>
                      <div className="col">
                        <div className="categories-orders flt-left">
                          <button className="b-none bg-trans" type="button">
                            <TiShoppingCart />
                            <select
                              name=""
                              id=""
                              className="b-none bg-trans w-50 f-12"
                            >
                              <option value="">Orders</option>
                              <option value="">Order List</option>
                              <option value="">Order Tracking</option>
                              <option value="">Order Details</option>
                            </select>
                          </button>
                        </div>
                      </div>
                      <div className="col">
                        <div className="categories-accounts">
                          <button type="button" className="b-none bg-trans">
                            <FaRupeeSign />
                            <select
                              name=""
                              id=""
                              className="b-none bg-trans w-70 f-12"
                            >
                              <option value="">Accounts</option>
                              <option value="">Order</option>
                              <option value="">Transactions</option>
                            </select>
                          </button>
                        </div>
                      </div>
                      <div className="col coupons-left">
                        <div className="categories-coupons">
                          <button type="button" className="b-none bg-trans">
                            <RiCoupon3Line />
                            <select
                              name=""
                              id=""
                              className="b-none bg-trans w-60 f-12"
                            >
                              <option value="">Coupons</option>
                              <option value="">List Coupons</option>
                              <option value="">Create Coupons</option>
                            </select>
                          </button>
                        </div>
                      </div>
                      <div className="col">
                        <div className="today-orders flt-left ms-5">
                          <button
                            type="button"
                            className="tdy-orders-bg b-none txt-gwhite hgt-28 br-rad-2 f-12"
                          >
                            <FaHandsHelping />
                            Today Orders
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Bismi Admin Panel */}
        <section>
          <div className="container-fluid Admin-pnl-head-bg mt-3">
            <div className="row">
              <div className="col-4">
                <div className="ps-21p">
                  <h6 className="mt-2 txt-brown">SUB CATEGORY</h6>
                  <p className="f-10">Bismi Admin Panel</p>
                </div>
              </div>
              <div className="col-8">
                <div class="input-group mb-3 w-30 ms-64 mt-3">
                  <span class="input-group-text f-12">Category</span>
                  <select className="form-control f-12" name="" id="">
                    <option value="">Dates</option>
                    <option value="">Nuts</option>
                    <option value="">Dry Fruits</option>
                    <option value="">Chocolates</option>
                    <option value="">Category Name</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Edit Modal */}
        <div
          class="modal fade"
          id="editCategoryModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog f-12">
            <div class="modal-content w-80 ms-5 margin-top-10p">
              <div class="modal-header">
                <h6 class="modal-title" id="exampleModalLabel">
                  Edit Sub Category
                </h6>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div class="mb-3">
                  <label for="" className="form-label">
                    Category ID
                  </label>
                  <input
                    type="text"
                    className="form-control f-12"
                    id=""
                    placeholder=""
                    value="ID0001"
                  />
                </div>
                <div class="mb-3">
                  <label for="" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control f-12"
                    id=""
                    placeholder=""
                    value="Honey Dates"
                  />
                </div>
                <div class="mb-3">
                  <label for="" className="form-label">
                    Status
                  </label>
                  <select className="form-control f-12" name="" id="">
                    <option value="">Stock</option>
                    <option value="">No Stock</option>
                  </select>
                </div>

                <div class="mb-3">
                  <label for="" className="form-label">
                    Price Range
                  </label>
                  <input
                    type="text"
                    className="form-control f-12"
                    id=""
                    placeholder=""
                    value="Rs. 600"
                  />
                </div>
                <div class="mb-3">
                  <label for="" className="form-label">
                    Image
                  </label>
                  <p>
                    <img
                      className="w-40 h20"
                      src={process.env.PUBLIC_URL + "assets/images/cashew.jpeg"}
                      alt=""
                      srcset=""
                    />
                  </p>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="bg-white b-none"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  class="bg-brown b-none width-34p txt-gwhite p-1 br-rad-2"
                >
                  Update Sub Category
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Delete Modal */}
        <div
          class="modal fade"
          id="deleteCategoryModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog f-12">
            <div class="modal-content margin-top-50p w-80 ms-5">
              <div class="modal-body">
                <div className="close-icons mb-4">
                  <button
                    type="button"
                    className="flt-right bg-white b-none"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <IoCloseOutline className="f-16" />
                  </button>
                </div>
                <div className="delete-icon txt-center">
                  <AiFillDelete className="delete-modal-icon" />
                </div>
                <div className="delete-content text-center">
                  <p className="fw-bold">Are you Sure?</p>
                  <p>Are you sure you want to remove this Category list</p>
                </div>
                <div className="delete-buttons txt-center">
                  <button
                    type="button"
                    class="me-2 mt-2 mb-4 bg-grey b-none txt-gwhite br-rad-2 p-1 f-12 w-10"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    class="bg-brown b-none txt-gwhite br-rad-2 p-1 f-12 w-30 txt-center"
                  >
                    Yes! Delete it
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* List Coupons Table */}
        <section>
          <div className="container-fluid d-flex flex-wrap">
            <div className="row">
              <div className="col-12">
                <div className="lst-coupons-tbl w-89 ml-7p p-3 bx-shd mt-3 mb-4">
                  <div className="search-category">
                    <div className="row">
                      <div className="col-7">
                        <div class="input-group  mt-0 mb-3 w-50 ">
                          <span class="input-group-text">
                            <IoIosSearch />
                          </span>
                          <input
                            type="text"
                            class="form-control h-40"
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-addon1"
                          />
                        </div>
                      </div>
                      <div className="col-5">
                        <button
                          type="button"
                          className="ml-63p mt-0 f-10 w-30 h-50 bg-brown b-none txt-gwhite br-4"
                        >
                          <AiOutlineAppstore className="sub-icon me-1" />
                          ADD SUB CATEGORY
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="categorytable-container">
                    <table className="table1 mt-0 f-12">
                      <thead className="thead1">
                        <tr className="">
                          <th>Sub-Category ID</th>
                          <th>Name</th>
                          <th>Image</th>
                          <th>Status</th>
                          <th>Price List Per Kg</th>
                          <th>Option</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>ID0001</td>
                          <td>Badam</td>
                          <td>
                            <img
                              className="w-20"
                              src={
                                process.env.PUBLIC_URL +
                                "assets/images/cashew.jpeg"
                              }
                              alt=""
                              srcset=""
                            />
                          </td>
                          <td className="coupon-status">
                            <p class="success1">Stock</p>
                          </td>
                          <td>Rs. 1000</td>
                          <td>
                            <button
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#editCategoryModal"
                              className="clr-lgt-bul me-3 f-14 bg-white b-none"
                            >
                              <CiEdit />
                            </button>
                            <button
                              type="button"
                              className="clr-rd f-14 bg-white b-none"
                              data-bs-toggle="modal"
                              data-bs-target="#deleteCategoryModal"
                            >
                              <RiDeleteBin5Fill />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>ID0002</td>
                          <td>Pista</td>
                          <td>
                            <img
                              className="w-20"
                              src={
                                process.env.PUBLIC_URL +
                                "assets/images/cashew.jpeg"
                              }
                              alt=""
                              srcset=""
                            />
                          </td>
                          <td>
                            <p class="success1">Stock</p>
                          </td>
                          <td>Rs.1800</td>
                          <td>
                            <button
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#editCategoryModal"
                              className="clr-lgt-bul me-3 f-14 bg-white b-none"
                            >
                              <CiEdit />
                            </button>
                            <button
                              type="button"
                              className="clr-rd f-14 bg-white b-none"
                              data-bs-toggle="modal"
                              data-bs-target="#deleteCategoryModal"
                            >
                              <RiDeleteBin5Fill />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>ID0003</td>
                          <td>Casew</td>
                          <td>
                            <img
                              className="w-20"
                              src={
                                process.env.PUBLIC_URL +
                                "assets/images/cashew.jpeg"
                              }
                              alt=""
                              srcset=""
                            />
                          </td>
                          <td>
                            <p class="pending1">No Stock</p>
                          </td>
                          <td>Rs.9000</td>
                          <td>
                            <button
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#editCategoryModal"
                              className="clr-lgt-bul me-3 f-14 bg-white b-none"
                            >
                              <CiEdit />
                            </button>
                            <button
                              type="button"
                              className="clr-rd f-14 bg-white b-none"
                              data-bs-toggle="modal"
                              data-bs-target="#deleteCategoryModal"
                            >
                              <RiDeleteBin5Fill />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>ID0004</td>
                          <td>Walnuts</td>
                          <td>
                            <img
                              className="w-20"
                              src={
                                process.env.PUBLIC_URL +
                                "assets/images/cashew.jpeg"
                              }
                              alt=""
                              srcset=""
                            />
                          </td>
                          <td>
                            <p class="pending1">No Stock</p>
                          </td>
                          <td>Rs.3000</td>
                          <td>
                            <button
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#editCategoryModal"
                              className="clr-lgt-bul me-3 f-14 bg-white b-none"
                            >
                              <CiEdit />
                            </button>
                            <button
                              type="button"
                              className="clr-rd f-14 bg-white b-none"
                              data-bs-toggle="modal"
                              data-bs-target="#deleteCategoryModal"
                            >
                              <RiDeleteBin5Fill />
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Category;
