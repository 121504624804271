import React from "react";
import { useNavigate } from "react-router-dom";
import "../Challenge/Challenge.css";
import { IoIosClose } from "react-icons/io";
import ReCAPTCHA from "react-google-recaptcha";

const Challenge = () => {
  const navigate = useNavigate();

  const navigateToLogin = () => {
    navigate("/Login");
  };

  function onChange(value) {
    console.log("Captcha value:", value);
  }

  return (
    <div>
      <main>
        {/* Challenge Section */}
        <section>
          <div className="container-fluid d-flex flex-wrap">
            {/* Challenge Header */}
            <div className="create-section">
              <div className="row">
                <div className="col-12">
                  <img
                    fluid
                    className="crt-acc-back w-100"
                    src={
                      process.env.PUBLIC_URL +
                      "assets/images/CreateAccount-back.jpg"
                    }
                    alt=""
                    srcset=""
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="crt-acc-cnt txt-gwhite ml-27-2p">
                    <div className="cntback-head">
                      <h2 className="f-35">Account</h2>
                    </div>
                    <div className="cntback-subhead">
                      <h5 className="f-20">Home / Challenge</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Challenge Section Body */}
            <div className="create-section-body form-bg mt-7p mb-7p w-60 p-5 ml-20p">
              <form action="" method="post">
                <div className="row">
                  <div className="col-12">
                    <div className="form">
                      <div className="form-group">
                        <button
                          onClick={navigateToLogin}
                          type="button"
                          class="b-none ml-100p bg-brown txt-gwhite close-btn"
                        >
                          <IoIosClose />
                        </button>
                      </div>
                      <div className="rbt-form">
                        <div className="rbt-head">
                          <div className="row">
                            <div className="col-12 txt-center">
                              <p>
                                To continue, let us know you're not a robot.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="rbt-box d-flex flex-wrap mt-4 mb-4 ml-26p">
                          <div className="row">
                            <div className="col-12">
                              <div className="">
                                <ReCAPTCHA
                                  className=""
                                  sitekey="Your client site key"
                                  onChange={onChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="btn-section txt-center mt-2">
                  <div className="row">
                    <div className="col-12">
                      <button
                        type="submit"
                        className="w-10 h-95 pb-1 bg-brown b-none f-14 txt-gwhite"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Challenge;
