import React from "react";
import "./Home.css";
import { Carousel } from "bootstrap";
import { useState, useEffect, useRef } from "react";
import carousel2 from "../assets/images/carousel2.png";
import carousel3 from "../assets/images/carousel3.png";
import caurosel1 from "../assets/images/caurosel1.png";
import giftbackimg from "../assets/images/gift-back-img.png";
import cashew from "../assets/images/cashew.jpeg";
import dryfruits from "../assets/images/dryfruits.png";
import dates from "../assets/images/dates.jpeg";
import cookies from "../assets/images/cookies.png";
import dryfruit1 from "../assets/images/dryfruit1.png";
import dryfruit2 from "../assets/images/dryfruit2.png";
import dryfruit3 from "../assets/images/dryfruit3.png";
import dryfruit4 from "../assets/images/dryfruit4.png";
import dryfruit5 from "../assets/images/dryfruit5.png";
import dryfruit6 from "../assets/images/dryfruit6.png";
import gallery1 from "../assets/images/special-gal-01.png";
import gallery2 from "../assets/images/special-gal-02.png";
import gallery3 from "../assets/images/special-gal-03.png";
import gallery4 from "../assets/images/special-gal-04.png";
import gallery5 from "../assets/images/special-gal-05.png";
import gallery6 from "../assets/images/special-gal-06.png";
import gallery7 from "../assets/images/special-gal-07.png";
import gallery8 from "../assets/images/special-gal-08.png";
import gallery9 from "../assets/images/special-gal-09.png";
import combo1 from "../assets/images/combo1.png";
import combo2 from "../assets/images/combo2.png";
import combo3 from "../assets/images/combo3.png";
import combo4 from "../assets/images/combo4.png";
import combo5 from "../assets/images/combo5.png";
import combo6 from "../assets/images/combo6.png";

const autoscrollimages = [
  combo1,
  combo2,
  combo3,
  combo1,
  combo4,
  combo5,
  combo3,
  combo6,
];

const Home = () => {
  const [animated, setAnimated] = useState(false);
  const aboutRef = useRef(null);
  const imageRef = useRef(null);

  useEffect(() => {
    // Initialize the Bootstrap Carousel component
    var carouselElement = document.querySelector("#carouselExampleControls");
    var carousel = new Carousel(carouselElement);
  }, []);

  // ------------------------------////
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          setAnimated(true);
        }
      },
      { threshold: 0.5 }
    );

    if (aboutRef.current) {
      observer.observe(aboutRef.current);
    }
    return () => {
      if (aboutRef.current) {
        observer.unobserve(aboutRef.current);
      }
    };
  }, []);

  return (
    <div>
      <main>
        {/* Caurosal Section */}
        <section id="home" className="d-flex justify-content-center ">
          <div className="container-fluid mt-8p">
            <div
              className="
      d-flex flex-1 flex-direction-row"
            >
              <div
                className="carouelimg1 pe-10"
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  height: "100vh",
                }}
              >
                <div
                  className="hovereffect d-flex align-items-center txt-left ps-4 ltr_spacing-5"
                  style={{
                    color: "white",
                    backgroundImage: `url(${dryfruits})`,
                    backgroundSize: "cover",
                    flex: "1",
                  }}
                >
                  <h3 style={{ marginTop: "28%" }}>Cashew Nuts & Pista</h3>
                  <div className="hover-background"></div>
                </div>

                <div
                  className=""
                  style={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="hovereffect mt-10 d-flex align-items-center txt-left ps-4 ltr_spacing-5"
                    style={{
                      color: "white",
                      backgroundImage: `url(${cashew})`,
                      backgroundSize: "cover",
                      flex: "1",
                    }}
                  >
                    <h3 style={{ marginTop: "28%" }}>Dry Fruits & Cashews</h3>
                    <div className="hover-background"></div>
                  </div>
                </div>
              </div>

              <div
                className="carouselgroupimg"
                style={{
                  width: "600px",
                  height: "400px",
                  flex: "2",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {" "}
                {/* Set fixed height for carousel */}
                <div style={{ width: "100%" }}>
                  <div
                    id="carouselExampleControls"
                    className="carousel slide"
                    data-bs-ride="carousel"
                    data-bs-interval="2000"
                    style={{
                      height: "100vh",
                      flex: "1",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="carousel-inner" style={{ height: "100vh" }}>
                      <div
                        className="carousel-item active"
                        style={{ height: "100vh" }}
                      >
                        <img
                          src={carousel3}
                          className="d-block w-100"
                          alt="First slide"
                          style={{ height: "100vh" }}
                        />
                      </div>
                      <div className="carousel-item" style={{ height: "100%" }}>
                        <img
                          src={caurosel1}
                          className="d-block w-100"
                          alt="Second slide"
                          style={{ height: "100vh" }}
                        />
                      </div>
                      <div className="carousel-item" style={{ height: "100%" }}>
                        <img
                          src={carousel2}
                          className="d-block w-100"
                          alt="Third slide"
                          style={{ height: "100vh" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="ps-10"
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  height: "100vh",
                }}
              >
                <div
                  className="hovereffect d-flex align-items-center txt-left ps-4 ltr_spacing-5"
                  style={{
                    color: "white",
                    backgroundImage: `url(${cookies})`,
                    backgroundSize: "cover",
                    flex: "1",
                  }}
                >
                  <h3>Chocolates & Cookies</h3>
                  <div className="hover-background"></div>
                </div>

                <div
                  className=""
                  style={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="hovereffect mt-10 d-flex align-items-center txt-left ps-4 ltr_spacing-5"
                    style={{
                      color: "white",
                      backgroundImage: `url(${dates})`,
                      backgroundSize: "cover",
                      flex: "1",
                    }}
                  >
                    <h3>Premium Dates & Nuts</h3>
                    <div className="hover-background"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* About Section */}
        <section id="aboutUs">
          <div className="container-fluid">
            <div className="section2">
              <div className="row">
                <div
                  className="col-8"
                  style={{ textAlign: "justify", paddingLeft: "2%" }}
                >
                  <div className="about-head">
                    <h1 className="txt-brown mb-4" style={{ marginTop: "11%" }}>
                      About Us
                    </h1>
                  </div>
                  <div
                    className="about-body f-18 ltr_spacing-05"
                    ref={aboutRef}
                  >
                    <div
                      className={`about-body-cnt mt-3 mb-5 ${
                        animated ? "animate-left" : ""
                      }`}
                    >
                      <h5 style={{ color: "#8c8e0e" }}>OUR VISION</h5>
                      <p>
                        "Our goal is to become the preferred choice for all our
                        customers seeking Dates, Nuts, Cookies, Chocolates, as
                        well as imported Beverages and Wines. We aspire to
                        establish ourselves as the benchmark for product quality
                        and impeccable service. Every item in our product line
                        is meticulously crafted using natural ingredients to
                        ensure authenticity and to capture the essence of its
                        origin. Our foremost commitment is to consistently
                        delight our customers by delivering products of the
                        highest quality, without compromise."
                      </p>
                    </div>
                    <div
                      className={`about-body-cnt mt-3 mb-5 ${
                        animated ? "animate-left" : ""
                      }`}
                    >
                      <h5 style={{ color: "#8c8e0e" }}>OUR MISSION</h5>
                      <p>
                        "Our primary objective is to generate sustainable value
                        for our stakeholders through the production and
                        marketing of nuts, dates, cookies, chocolates,
                        beverages, and wines, maintaining the highest quality
                        standards. We operate on a vertically integrated
                        business model, ensuring meticulous control over the
                        quality and traceability of our nuts from the best
                        plantations to our customers worldwide. Our focus is on
                        delivering genuine value to our customers through our
                        premium offerings. We are dedicated to achieving
                        customer satisfaction, as we believe that satisfied
                        customers will attract many more."
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <img
                    src={process.env.PUBLIC_URL + "assets/images/about-img.png"}
                    className={`w-100 ms-3 ${
                      animated ? "animate-right" : "animate-right-initial"
                    }`}
                    alt=""
                    srcSet=""
                    ref={imageRef}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Dry Druits Section */}
        <section id="dryfruits">
          <div className="container-fluid">
            <div className="section3">
              <div className="row">
                <div className="col-12 ps-4">
                  <div className="section3-head">
                    <h1 className="txt-brown ms-2 mt-7p mb-4">Dry Fruits</h1>
                  </div>
                  <div className="section3-body">
                    <div className="row " style={{ marginLeft: "-3px" }}>
                      <div className="col cashewimg" style={{ padding: "7px" }}>
                        <div className="casheww">
                          <img
                            src={dryfruit5}
                            className="w-100 h-91"
                            alt=""
                            srcset=""
                          />
                        </div>
                      </div>
                      <div className="col cashewimg" style={{ padding: "7px" }}>
                        <div className="casheww">
                          <img
                            src={dryfruit2}
                            className="w-100 h-100"
                            alt=""
                            srcset=""
                          />
                        </div>
                      </div>
                      <div className="col cashewimg" style={{ padding: "7px" }}>
                        <div className="casheww">
                          <img
                            src={dryfruit3}
                            className="w-100 h-91"
                            alt=""
                            srcset=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row " style={{ marginLeft: "-3px" }}>
                      <div className="col cashewimg" style={{ padding: "7px" }}>
                        <div className="casheww">
                          <img
                            src={dryfruit4}
                            className="w-100 h-91"
                            alt=""
                            srcset=""
                          />
                        </div>
                      </div>
                      <div className="col cashewimg" style={{ padding: "7px" }}>
                        <div className="casheww">
                          <img
                            src={dryfruit1}
                            className="w-100 h-100"
                            alt=""
                            srcset=""
                          />
                        </div>
                      </div>
                      <div className="col cashewimg" style={{ padding: "7px" }}>
                        <div className="casheww">
                          <img
                            src={dryfruit6}
                            className="w-100 h-91"
                            alt=""
                            srcset=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Gift Combos */}
        <section id="giftcombos">
          <div className="">
            <div className="section4 ">
              <div className="row">
                <div className="">
                  <div className="section4-head ">
                    <div className="row ">
                      <div className="col">
                        <h1 className="txt-brown ms-4 mt-12p">Gift Combos</h1>
                      </div>
                      <div className="col-6">
                        <button className="d-none gift-view-btn flt-right me-3 mt-2 txt-gwhite f-16 w-15 h-60 bdr-rad-4 bg-brown b-none">
                          View all
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="section-body mt-4 ms-4">
                    <div
                      className="row "
                      style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        backgroundImage: `url(${giftbackimg})`,
                        backgroundSize: "cover",
                        height: "400px",
                      }}
                    >
                      <div
                        style={{
                          display: "inline-block",
                          transition: "transform 0.5s linear",

                          marginTop: "6%",
                          overflowX: "hidden",
                          width: "100%",
                          height: "400px",
                        }}
                      >
                        <div className="image-slider scroll">
                          {autoscrollimages.map((image, index) => (
                            <img
                              key={index}
                              src={image}
                              style={{
                                marginLeft: "20px",
                                width: "20%",
                                borderRadius: "5px",
                              }}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Our Special Gallery */}
        <section id="gallery" className="">
          <div className="container-fluid">
            <div className="section5  mb-3">
              <div className="row">
                <div className="col-12">
                  <div className="section5-head">
                    <h1 className="txt-brown ms-3 " style={{ marginTop: "8%" }}>
                      Our Special Gallery
                    </h1>
                  </div>
                  <div className="section5-body mt-4p">
                    <div className="row">
                      <div
                        className="col txt-center hover-splgallery"
                        style={{ padding: "7px" }}
                      >
                        <img
                          className="w-100 mt-3 "
                          src={gallery1}
                          alt=""
                          srcset=""
                        />
                      </div>
                      <div
                        className="col txt-center scale-on-hover"
                        style={{ paddingRight: "0px" }}
                      >
                        <img
                          src={gallery7}
                          className="w-100 gallery1"
                          alt=""
                          srcset=""
                        />
                        <img
                          src={gallery6}
                          className="w-100"
                          style={{ marginTop: "5%" }}
                          alt=""
                          srcset=""
                        />
                      </div>
                      <div className="col" style={{ paddingRight: "0px" }}>
                        <img
                          src={gallery2}
                          className="w-100 "
                          alt=""
                          srcset=""
                        />
                        <img
                          src={gallery5}
                          className="w-100"
                          style={{ marginTop: "5%" }}
                          alt=""
                          srcset=""
                        />
                      </div>
                      <div className="col" style={{ paddingRight: "0px" }}>
                        <img
                          src={gallery4}
                          className="w-100 "
                          alt=""
                          srcset=""
                        />
                        <img
                          src={gallery8}
                          className="w-100"
                          style={{ marginTop: "5%" }}
                          alt=""
                          srcset=""
                        />
                      </div>
                      <div className="col">
                        <img
                          src={gallery3}
                          className="w-100 "
                          alt=""
                          srcset=""
                        />
                        <img
                          src={gallery9}
                          className="w-100"
                          style={{ marginTop: "5%" }}
                          alt=""
                          srcset=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Home;
