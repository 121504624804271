import React ,{useState} from "react";
import { RiDeleteBin6Line } from "react-icons/ri";

const Wishlist = () => {
  const [wishlistval, setwishlistval] = useState([
    {
      image: "cashew.jpeg",
      product: "Premium Dry Fruits and Nuts 3kg",
      price: "Rs.500.00",
    },
    {
      image: "caurosal_almonds.jpeg",
      product: "Premium Dry Fruits and Nuts 3kg",
      price: "Rs.1500.00",
    },
    {
      image: "dates.jpeg",
      product: "Premium Dry Fruits and Nuts 3kg",
      price: "Rs.2500.00",
    },
  ]);
  return (
    <div>
      <div>
        <div className="back_img">
          <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
            <div style={{ flex: 1 }}>
              <img
                className="w-100"
                style={{ marginTop: -80 }}
                src={process.env.PUBLIC_URL + "assets/images/wishlist_back.png"}
                alt=""
                srcset=""
              />
            </div>
          </div>
          <div style={{ flex: 1, flexDirection: "row", display: "flex" }}>
            <div style={{ flex: 1, marginLeft: 1000 }}>
              <h2
                className="txt-brown"
                style={{
                  fontFamily: "Jim Nightshade, cursive",
                  marginTop: -130,
                  fontWeight: "bolder",
                }}
              >
                WISHLIST
              </h2>
              <h6 className="txt-brown">Home / Wishlist</h6>
            </div>
          </div>
        </div>
      </div>
      <table
        className="w-80 f-14"
        style={{ marginTop: 60, marginBottom: 60, marginLeft: 150 }}
      >
        <thead style={{ backgroundColor: "#efefef" }} className="fw-normal">
          <tr>
            <th style={{ fontWeight: "normal" }}>IMAGE</th>
            <th style={{ fontWeight: "normal" }}>PRODUCT</th>
            <th style={{ fontWeight: "normal" }}>PRICE</th>
            <th style={{ fontWeight: "normal" }}>PURCHASE</th>
            <th style={{ fontWeight: "normal" }}>REMOVE</th>
          </tr>
        </thead>
        <tbody className="f-14">
          {wishlistval.map((val, index) => (
            <tr key={index} style={{ paddingTop: 60 }}>
              <td>
                  <img
                    style={{ width: 100 }}
                    src={`../assets/images/${val.image}`}
                    alt=""
                  />
              </td>
              <td>{val.product}</td>
              <td>{val.price}</td>
              <td>
                <button
                  className="bg-brown b-none txt-gwhite f-12"
                  style={{ borderRadius: 2, padding: 6 }}
                >
                  Select Option
                </button>
              </td>
              <td>
                <button className="bg-white txt-brown b-none f-18">
                  <RiDeleteBin6Line />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Wishlist;