import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdHome } from "react-icons/md";
import "../Products/ProductList.css";
import { FaPhoneAlt } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
import { IoIosSearch } from "react-icons/io";
import { BsFilterLeft } from "react-icons/bs";
import { AiOutlineAppstore } from "react-icons/ai";
import { GoPackage } from "react-icons/go";
import { TiShoppingCart } from "react-icons/ti";
import { FaRupeeSign } from "react-icons/fa";
import { RiCoupon3Line } from "react-icons/ri";
import { FaHandsHelping } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoIosStarOutline } from "react-icons/io";

const ProductList = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("");

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);

    // You can add more conditions or redirect to other pages as needed
    if (selectedValue === "CreateProduct") {
      navigate("/CreateProduct");
    }
  };

  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const toggleMenuVisibility = () => {
    setIsMenuVisible(!isMenuVisible);
  };
  return (
    <div>
      <main>
        {/* Header Section*/}
        <section>
          <div className="container-fluid bg-brown txt-gwhite">
            <div className="header-section d-flex flex-wrap">
              <div className="row">
                <div className="col-12">
                  <div className="dashboard-head">
                    <div className="row">
                      <div className="col-6">
                        <div className="admin-head-home txt-left pt-2-5 ">
                          <MdHome />
                        </div>
                        <div className="admin-head-address txt-left f-12 mt-1 ml-10p p-3">
                          <small>
                            3/66 3,ST-22 Kuruvilaikadu,Eraviputhurkadai.
                          </small>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="admin-home-number">
                          <div className="admin-phone-icon f-12 mt-1">
                            <FaPhoneAlt />
                          </div>
                          <div className="admin-phone-number f-10 txt-right">
                            <p>73739 32235</p>
                            <p className="admin-head-num2 ms-5">94880 68189</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Search Section */}
        <section>
          <div className="container-fluid">
            <div className="search-section d-flex flex-wrap txt-center mt-4">
              <div className="row">
                <div className="col-12">
                  <div className="dashboard-search">
                    <div className="row">
                      <div className="col-2">
                        <div className="dashboard-logo">
                          <h3>BISMI.</h3>
                        </div>
                      </div>
                      <div className="col-8">
                        <div className="dashboard-search">
                          <div className="row">
                            <div className="col-2 search-loc">
                              <div className="search-location">
                                <p className="pos-abs mt-1">
                                  <IoLocationOutline />
                                </p>
                                <p className="mt-1">Search</p>
                              </div>
                            </div>
                            <div className="col-8 txt-left">
                              <input
                                className="w-100 h-36"
                                placeholder="Search List.."
                                type="text"
                                name=""
                                id=""
                              />
                            </div>
                            <div className="col-2 txt-left">
                              <button className="search-btn" type="button">
                                <IoIosSearch className="mt--5 txt-gwhite" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="dashboard-profile mt-1">
                          <div className="row">
                            <div className="col">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "assets/images/dash-profile.jpg"
                                }
                                alt=""
                                className="dash-img"
                              />
                            </div>
                            <div className="col txt-left">
                              <p className="f-10 ms-3 w-100 mt-1">
                                Hello,
                                <br />
                                <small className="fw-bold">My Account</small>
                              </p>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Categories Section */}
        <section>
          <div className="container-fluid">
            <div className="categories-section">
              <div className="row">
                <div className="col-12">
                  <div className="categories-select f-14 ml-7p">
                    <div className="row">
                      <div className="col">
                        <div className="filter-cat">
                          <button
                            type="button"
                            className="txt-gwhite b-none bg-brown p-1 br-rad-2"
                          >
                            <BsFilterLeft />
                            <small>All Categories</small>
                          </button>
                        </div>
                      </div>
                      <div className="col">
                        <div className="categories-dash">
                          <button
                            type="button"
                            className=" txt-brown bg-trans b-none"
                          >
                            <AiOutlineAppstore />
                            <small>Dashboard</small>
                          </button>
                        </div>
                      </div>
                      <div className="col flt-left">
                        <div className="categories-products">
                          <button type="button" className="bg-trans b-none">
                            <GoPackage />
                            <select
                              name=""
                              id=""
                              className="b-none bg-trans w-60 f-12"
                              onChange={handleSelectChange}
                              value={selectedOption}
                            >
                              <option value="">Products</option>
                              <option value="">Category</option>
                              <option value="">Product List</option>
                              <option value="">Product Detail</option>
                              <option value="CreateProduct">Add Product</option>
                            </select>
                          </button>
                        </div>
                      </div>
                      <div className="col">
                        <div className="categories-orders flt-left">
                          <button className="b-none bg-trans" type="button">
                            <TiShoppingCart />
                            <select
                              name=""
                              id=""
                              className="b-none bg-trans w-50 f-12"
                            >
                              <option value="">Orders</option>
                              <option value="">Order List</option>
                              <option value="">Order Tracking</option>
                              <option value="">Order Details</option>
                            </select>
                          </button>
                        </div>
                      </div>
                      <div className="col">
                        <div className="categories-accounts">
                          <button type="button" className="b-none bg-trans">
                            <FaRupeeSign />
                            <select
                              name=""
                              id=""
                              className="b-none bg-trans w-70 f-12"
                            >
                              <option value="">Accounts</option>
                              <option value="">Order</option>
                              <option value="">Transactions</option>
                            </select>
                          </button>
                        </div>
                      </div>
                      <div className="col coupons-left">
                        <div className="categories-coupons">
                          <button type="button" className="b-none bg-trans">
                            <RiCoupon3Line />
                            <select
                              name=""
                              id=""
                              className="b-none bg-trans w-60 f-12"
                            >
                              <option value="">Coupons</option>
                              <option value="">List Coupons</option>
                              <option value="">Create Coupons</option>
                            </select>
                          </button>
                        </div>
                      </div>
                      <div className="col">
                        <div className="today-orders flt-left ms-5">
                          <button
                            type="button"
                            className="tdy-orders-bg b-none txt-gwhite hgt-28 br-rad-2 f-12"
                          >
                            <FaHandsHelping />
                            Today Orders
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Bismi Admin Panel */}
        <section>
          <div className="container-fluid Admin-pnl-head-bg mt-3">
            <div className="row">
              <div className="col-4">
                <div className="ps-21p">
                  <h6 className="mt-2 txt-brown">PRODUCT LIST</h6>
                  <p className="f-10">Bismi Admin Panel</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* List Coupons Table */}
        <section>
          <div className="container-fluid d-flex flex-wrap">
            <div className="row">
              <div className="col-12">
                <div className="lst-coupons-tbl w-89 ml-7p p-3 bx-shd mt-4">
                  <div className="add-prod-btn">
                    <div className="row">
                      <div className="col-4">
                        <button
                          type="button"
                          className="bg-brown b-none txt-gwhite f-12 w-30 h-70 br-rad-2"
                        >
                          + Add Product
                        </button>
                      </div>
                      <div className="col-8">
                        <div class="input-group  w-40 ml-52p">
                          <span class="input-group-text">
                            <IoIosSearch />
                          </span>
                          <input
                            type="text"
                            class="form-control h-36"
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-addon1"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="product-list">
                    <div className="row">
                      <div className="col-4">
                        <div className="product1 box-shadow ms-5 w-84">
                          <div className="product-lst1">
                            <div className="row back-grey ms-0 f-12 mt-3 ps-2 pt-3  pb-3">
                              <div className="col-2">
                                <p className="discount-percent">25%</p>
                              </div>
                              <div className="col-8 txt-center">
                                <img
                                  className="w-91 h-100"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "assets/images/cashew.jpeg"
                                  }
                                  alt=""
                                  srcset=""
                                />
                              </div>
                              <div className="col-2">
                                <button
                                  type="button"
                                  className="txt-gwhite bg-brown b-none"
                                  onClick={toggleMenuVisibility}
                                >
                                  <BsThreeDotsVertical className="pb-1 f-14" />
                                </button>
                                {isMenuVisible && (
                                  <ul className="edit-bx-border edit-lists">
                                    <Link
                                      to="/EditProduct"
                                      className="txt-decoration_0 txt-black"
                                    >
                                      <li className="edit-li p-1 mt-1 ml--32 txt-center txt-decoration_0 ">
                                        Edit
                                      </li>
                                    </Link>
                                    <li className="edit-li p-1 mt-1 ml--32 txt-center">
                                      Delete
                                    </li>
                                    <Link
                                      to="/ProductDetails"
                                      className="txt-decoration_0 txt-black"
                                    >
                                      <li className="edit-li p-1 mt-1 ml--32 txt-center">
                                        Overview
                                      </li>
                                    </Link>
                                  </ul>
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <div className="product-rate f-12 mt-3">
                                  <div className="row">
                                    <div className="col-4">
                                      <p className="ps-2">₹ 2455</p>
                                    </div>
                                    <div className="col-4">
                                      <p className="ps-2 strike">₹ 3455</p>
                                    </div>
                                    <div className="col-4">
                                      <div className="rate-icons mt-2 flt-right">
                                        <IoIosStarOutline />
                                        <IoIosStarOutline />
                                        <IoIosStarOutline />
                                        <IoIosStarOutline />
                                        <IoIosStarOutline />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <div className="product-info f-12">
                                    <div className="row prod-left mt-1">
                                      <div className="col-6 fw-bold">
                                        <p>Premium Cashew</p>
                                      </div>
                                      <div className="col-6">
                                        <p className="txt-brown">
                                          Category: Nuts
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row prod-description-left">
                                <div className="col-12">
                                  <div className="product-description f-12">
                                    <p className="mt-3 p-2">
                                      Lorem ipsum dolor sit amet consectetur
                                      adipisicing elit. Inventore reiciendis,
                                      incidunt reprehenderit doloremque esse
                                      vero officia.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="product1 box-shadow ms-5 w-84">
                          <div className="product-lst1">
                            <div className="row back-grey ms-0 f-12 mt-3 ps-2 pt-3  pb-3">
                              <div className="col-2">
                                <p className="discount-percent">25%</p>
                              </div>
                              <div className="col-8 txt-center">
                                <img
                                  className="w-91 h-100"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "assets/images/cashew.jpeg"
                                  }
                                  alt=""
                                  srcset=""
                                />
                              </div>
                              <div className="col-2">
                                <button
                                  type="button"
                                  className="txt-gwhite bg-brown b-none"
                                >
                                  <BsThreeDotsVertical className="pb-1 f-14" />
                                </button>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <div className="product-rate f-12 mt-3">
                                  <div className="row">
                                    <div className="col-4">
                                      <p className="ps-4">₹ 2455</p>
                                    </div>
                                    <div className="col-4">
                                      <p className="ps-4 strike">₹ 3455</p>
                                    </div>
                                    <div className="col-4">
                                      <div className="rate-icons mt-2 flt-right">
                                        <IoIosStarOutline />
                                        <IoIosStarOutline />
                                        <IoIosStarOutline />
                                        <IoIosStarOutline />
                                        <IoIosStarOutline />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <div className="product-info f-12">
                                    <div className="row prod-left mt-1">
                                      <div className="col-6 fw-bold">
                                        <p>Premium Cashew</p>
                                      </div>
                                      <div className="col-6">
                                        <p className="txt-brown">
                                          Category: Nuts
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row prod-description-left">
                                <div className="col-12">
                                  <div className="product-description f-12">
                                    <p className="mt-3 p-2">
                                      Lorem ipsum dolor sit amet consectetur
                                      adipisicing elit. Inventore reiciendis,
                                      incidunt reprehenderit doloremque esse
                                      vero officia.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-4">
                        <div className="product1 box-shadow ms-5 w-84">
                          <div className="product-lst1">
                            <div className="row back-grey ms-0 f-12 mt-3 ps-2 pt-3  pb-3">
                              <div className="col-2">
                                <p className="discount-percent">25%</p>
                              </div>
                              <div className="col-8 txt-center">
                                <img
                                  className="w-91 h-100"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "assets/images/cashew.jpeg"
                                  }
                                  alt=""
                                  srcset=""
                                />
                              </div>
                              <div className="col-2">
                                <button
                                  type="button"
                                  className="txt-gwhite bg-brown b-none"
                                >
                                  <BsThreeDotsVertical className="pb-1 f-14" />
                                </button>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <div className="product-rate f-12 mt-3">
                                  <div className="row">
                                    <div className="col-4">
                                      <p className="ps-4">₹ 2455</p>
                                    </div>
                                    <div className="col-4">
                                      <p className="ps-4 strike">₹ 3455</p>
                                    </div>
                                    <div className="col-4">
                                      <div className="rate-icons mt-2 flt-right">
                                        <IoIosStarOutline />
                                        <IoIosStarOutline />
                                        <IoIosStarOutline />
                                        <IoIosStarOutline />
                                        <IoIosStarOutline />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <div className="product-info f-12">
                                    <div className="row prod-left mt-1">
                                      <div className="col-6 fw-bold">
                                        <p>Premium Cashew</p>
                                      </div>
                                      <div className="col-6">
                                        <p className="txt-brown">
                                          Category: Nuts
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row prod-description-left">
                                <div className="col-12">
                                  <div className="product-description f-12">
                                    <p className="mt-3 p-2">
                                      Lorem ipsum dolor sit amet consectetur
                                      adipisicing elit. Inventore reiciendis,
                                      incidunt reprehenderit doloremque esse
                                      vero officia.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default ProductList;
